import React, { useState, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import './SignUpPage.css';

// Firebase Imports
import {
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signInWithRedirect,
  getRedirectResult,
} from 'firebase/auth';

// Functions and Context
import { auth, googleProvider } from '../firebase';
import { AuthContext } from '../AuthContext';

// Components
import ProcessingPage from './ProcessingPage';
import SocialProfileCarousel from './homeComponent/socialprofileCarousel'; // Import the new component

// Images
import googleLogo from '../assets/images/google-official.png'; // Updated import
import checkMark from '../assets/images/check-sign.png'; // Updated import

const SignUpPage = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(
    localStorage.getItem('isLoading') === 'true'
  );
  const [emailValidationMsg, setEmailValidationMsg] = useState('');
  const [buttonSignUpText, setButtonSignUpText] = useState('Get started free');

  useEffect(() => {
    if (currentUser) {
      navigate('/dashboard');
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    const handleRedirectResult = async () => {
      try {
        const result = await getRedirectResult(auth);
        if (result?.user) {
          navigate('/dashboard');
        }
        localStorage.setItem('isLoading', 'false');
        setIsLoading(false);
      } catch (error) {
        localStorage.setItem('isLoading', 'false');
        setIsLoading(false);
      }
    };

    if (isSignInWithEmailLink(auth, window.location.href)) {
      let emailForSignIn = window.localStorage.getItem('emailForSignIn');
      if (!emailForSignIn) {
        emailForSignIn = window.prompt(
          'Please provide your email for confirmation'
        );
      }
      signInWithEmailLink(auth, emailForSignIn, window.location.href)
        .then(() => {
          window.localStorage.removeItem('emailForSignIn');
          navigate('/dashboard');
        })
        .catch(() => {
          // Handle error
        });
    } else {
      handleRedirectResult();
    }
  }, [navigate]);

  const actionCodeSettings = {
    url: window.location.origin + '/signup',
    handleCodeInApp: true,
  };

  const handleEmailSignUp = async () => {
    const emailRegex =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      setEmailValidationMsg('Please enter a valid email address.');
      return;
    }
    setEmailValidationMsg('');
    setButtonSignUpText('Sending Email...');

    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);
      alert('Check your email for the sign-in link.');
      setButtonSignUpText('Resend');
    } catch (error) {
      setButtonSignUpText('Resend');
    }
  };

  const handleGoogleSignUp = async () => {
    try {
      localStorage.setItem('isLoading', 'true');
      await signInWithRedirect(auth, googleProvider);
      navigate('/dashboard');
    } catch (error) {
      localStorage.setItem('isLoading', 'false');
    }
  };

  return (
    <div className="signup-page">
      <Helmet>
        <title>Bumpups - Sign Up</title>
        <meta
          name="description"
          content="Get started with Bumpups and enjoy 60 minutes of free. No credit card required."
        />
      </Helmet>

      {isLoading ? (
        <ProcessingPage />
      ) : (
        <div className="signup-wrapper"> {/* New wrapper div */}
          <div className="signup-content">
            {/* Left Section - Marketing Text */}
            <div className="signup-left">
              <h2>
                Join thousands worldwide who chat with their videos using Bumpups.
              </h2>
              <ul className="feature-list">
                <li>
                  <img src={checkMark} alt="Checkmark" className="checkmark-icon" />
                  Support for local and YouTube videos
                </li>
                <li>
                  <img src={checkMark} alt="Checkmark" className="checkmark-icon" />
                  Deliver insights across all types of videos
                </li>
                <li>
                  <img src={checkMark} alt="Checkmark" className="checkmark-icon" />
                  Get started with 60 minutes free
                </li>
              </ul>
            </div>

            {/* Right Section - Sign Up Form */}
            <div className="signup-form-container">
              <button className="google-button" onClick={handleGoogleSignUp}>
                <div className="google-logo-container">
                  <img
                    src={googleLogo}
                    alt="Google logo"
                    className="google-logo-left"
                  />
                </div>
                <span className="google-signup-text">Sign up with Google</span>
              </button>

              <div className="divider-signpage">
                <span className="divider-text-signpage">OR</span>
              </div>

              <div className="input-group">
                <label className="email-label">
                  <strong>* Your Email</strong> (required)
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="email-input"
                />
              </div>
              {emailValidationMsg && (
                <p className="email-validation-msg">{emailValidationMsg}</p>
              )}

              <button className="continue-button" onClick={handleEmailSignUp}>
                {buttonSignUpText}
              </button>

              <p className="terms-text">
                By signing up, you agree to Bumpups's{' '}
                <Link to="/terms-of-service" className="highlight-link">
                  Terms of Service
                </Link>{' '}
                and{' '}
                <Link to="/privacy-policy" className="highlight-link">
                  Privacy Policy
                </Link>
                .
              </p>
            </div>
          </div>
          <div className="trusted-text">
            Trusted by users across industries
          </div>

          <SocialProfileCarousel />
        </div>
      )}
    </div>
  );
};

export default SignUpPage;
