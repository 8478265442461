import React from 'react';
import { Helmet } from 'react-helmet';
import './TermsOfService.css';

//IMPORT UI
import Footer from './Footer';



const TermsOfService = () => {
  return (
    <div className="terms-container">
      <Helmet>
      <title>Bumpups - Terms of Service</title>
      <meta name="description" content="Read Bumpups's Terms of Service to understand the rules, terms and policies that govern the use of our platform." />
    </Helmet>

      <h1 className="terms-title">Terms of Service</h1>
      <div className="terms-text-container">
        <p>Effective date: April 14th, 2024</p>
        <h3>Introduction</h3>
        <p>Welcome to Bumpups. Thank you for using the Bumpups platform and the products, services, and features we make available to you as part of the platform (collectively, the "Services"). PLEASE READ ON TO LEARN THE RULES AND RESTRICTIONS THAT GOVERN YOUR USE OF THE SERVICES. IF YOU DO NOT UNDERSTAND THE AGREEMENT, OR DO NOT ACCEPT ANY PART OF IT, THEN YOU MAY NOT USE THE SERVICES.</p>
        <p>If you have any questions, comments, or concerns regarding these terms or the Services, please contact us at:</p>
        <p>Email: <a href="mailto:contact@bumpups.com" class="policyLinks">contact@bumpups.com</a></p>
        <p>Address: 5473 Blair Rd Ste 100 #112725, Dallas, TX 75231-4227</p>
        <h3>Your Services Provider</h3>
        <p>The entity providing the Services is Bumpups Software LLC, a corporation operating under the laws of Texas, located at 5473 Blair Rd Ste 100 #112725, Dallas, TX 75231-4227 (referred to as "Bumpups," "we," "us," or "our").</p>
        <h3>Applicable Terms</h3>
        <p>Your use of the Services is subject to these terms which may be updated from time to time (together, this "Agreement"). The Agreement includes the provisions in this document as well as those in the Privacy Policy. Your use of or participation in certain Services may also be subject to additional policies, rules, and/or conditions ("Additional Terms"), which are incorporated herein by reference, and you understand and agree that by using or participating in any such Services, you agree to also comply with these Additional Terms.</p>
       
        <h3>YouTube API Services Interaction</h3>
        <p>
          <strong>Scope and User Consent:</strong> Bumpups is an application designed to interact with YouTube API Services for the purpose of generating automated timestamps on YouTube videos. By using Bumpups, you acknowledge that the application will access and collect essential video metadata from your YouTube account to perform this function. No personal data from YouTube, such as video watch history or personal preferences, will be stored or used for any other purpose. All data is securely stored and encrypted to ensure your privacy. By using our service, you explicitly consent to these actions and agree to abide by both Bumpups's and <a href="https://www.youtube.com/t/terms" target="_blank" rel="noopener noreferrer" class="policyLinks">YouTube's terms and policies</a>.
        </p>
        <p>
          <strong>Limitations, Restrictions, and Compliance:</strong> Bumpups adheres to YouTube's API rate limits and data usage policies. Users are responsible for any actions that may cause Bumpups to exceed these limits. Bumpups reserves the right to terminate or suspend user accounts that violate these terms, <a href="https://www.youtube.com/t/terms" target="_blank" rel="noopener noreferrer" class="policyLinks">YouTube's Terms of Service</a>, or any applicable laws and regulations. By using Bumpups, you agree to comply with all applicable laws and regulations, including but not limited to <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer" class="policyLinks">Google's Privacy Policy</a>.
        </p>



       
        <h3>Who May Use the Services</h3>
        <h3>Age Requirements</h3>
        <p>You must be at least 13 years old to use the Services; however, children of all ages may use the Services if enabled by a parent or legal guardian.</p>

        <p> You represent and warrant that you are an individual of legal age to form a binding contract (or if not, you’ve received your parent’s or guardian’s permission to use the Services and have gotten your parent or guardian to agree to this Agreement on your behalf).</p>
        <h3>Permission by Parent or Guardian</h3>
        <p>If you are under 18, you represent that you have your parent or guardian’s permission to use the Services. Please have them read this Agreement with you.</p>
       
        <p> If you are a parent or legal guardian of a user under the age of 18, by allowing your child to use the Services, you are subject to the terms of this Agreement and responsible for your child’s activity on the Services.</p>
        <h3>Businesses</h3>
        <p>If you are using the Services on behalf of a company or organization, you represent that you have authority to act on behalf of that entity, and that such entity accepts this Agreement. You may not make your access credentials or account available to others outside your organization, and you are responsible for all activities that occur using your credentials.</p>
     
        <h1>Your Use of the Services</h1>
        <h3>Content on the Services</h3>
        <p>The Services utilize AI technology to automatically generate timestamps for YouTube videos. Bumpups does not assume responsibility for the accuracy or reliability of these AI-generated timestamps. If you find any discrepancies or issues with these timestamps, you may report them to us, but Bumpups is not obligated to take any action.</p>
        <h3>Bumpups Account</h3>
        <p>You'll need to create a Bumpups account, choose a username and password, and provide us with your contact details. It's your responsibility to give us accurate and up-to-date information. Don't use a name you don't have the legal right to use or impersonate someone else. Transferring your Bumpups account to someone else is not allowed without our prior written consent.</p>
        <h3>Third-Party Accounts</h3>
        <p>Additionally, you may be able to access certain parts or features of the Services by using your Bumpups Account credentials from other Services, such as those offered by Google. By using the Services through a Third-Party Account, you permit us to access certain information from such account for use by the Services.</p>
        <h3>Legal Use</h3>
        <p> Use our services only for lawful purposes and not on behalf of or for the benefit of any third party. If your use of our services is against the law, you're not allowed to use them. We're not responsible if you use our services in a way that's illegal.</p>
        <h3>Account Security</h3>
        <p>Keep your Bumpups account secure and don't share it with anyone else. You're responsible for all activity that happens under your account.</p>
        <h3>Your Information</h3>
        <p>Our Privacy Policy explains how we treat your personal data and protect your privacy when you use the Services.</p>
        <h3>Children’s Online Privacy Protection Act</h3>
        <p>The Children’s Online Privacy Protection Act (COPPA) mandates that online service providers must obtain parental consent before collecting personally identifiable information from children under 13 years old. Bumpups does not intentionally collect or request personally identifiable information from individuals under the age of 16. If you are under 16, please do not try to register for or use our Services, or send us any personal information. If we discover that we have collected personal information from someone under 16, we will take steps to promptly delete that information. If you suspect that a child under 16 has provided us with personal information, please contact us at contact@bumpups.com.</p>
        <h3>Cookies</h3>
        <p>Bumpups utilizes cookies to enhance your experience with our platform. By using Bumpups, you consent to the use of cookies in accordance with our Privacy Policy.</p>
        <p>Like many interactive platforms, we use cookies to remember user details for each visit, making it more convenient for you. These cookies are essential for certain functionalities on our platform. Our affiliate or advertising partners may also employ cookies.</p>
        <h3>Messaging</h3>
        <p>Messaging Features: As a part of Bumpups's Services, you may receive various communications, such as emails or SMS notifications. Upon registering for our Services, you'll get a welcome message along with instructions on how to opt-out of future messages. By registering and providing your contact details, you're giving Bumpups permission to send you information we believe may interest you.</p>
        <p>This may include automated messages sent to the contact information you've provided. You confirm that anyone you register for the Services, or for whom you provide contact details, has consented to receive these communications from Bumpups. You agree to indemnify Bumpups against any claims, damages, or losses arising from any breach of this agreement.</p>
        <div className="terms-section">
          <h3>Permissions and Restrictions</h3>
          <p>You're allowed to access and use Bumpups's Services as long as you adhere to this Agreement and all applicable laws.</p>
          <p>You agree not to:</p>
          <ul>
            <li>Violate anyone's intellectual property rights, including Bumpups's.</li>
            <li>Break any laws or regulations, including export control and privacy laws.</li>
            <li>Engage in harmful, fraudulent, or objectionable activities.</li>
            <li>Compromise the security of your Bumpups account or anyone else's.</li>
            <li>Attempt to obtain passwords or other security information from other users.</li>
            <li>Violate network security or crack encryption codes.</li>
            <li>Run spam or automated processes that interfere with the Services.</li>
            <li>Scrape or crawl any part of the Services or Content.</li>
            <li>Reverse engineer or attempt to discover the source code or algorithms of the Services.</li>
            <li>Use Bumpups's output to develop competing models.</li>
            <li>Buy, sell, or transfer API keys without our consent.</li>
            <li>Send us personal information of minors under the age of 13.</li>
            <li>Harvest identifiable information like usernames.</li>
            <li>Distribute unsolicited promotional content.</li>
            <li>Manipulate or falsify user engagement metrics.</li>
            <li>Misuse any reporting or flagging mechanisms.</li>
          </ul>
          <p>Violating any of these terms may result in termination of your access to the Services. We may use IP addresses to detect fraudulent or suspicious activity. If detected, we reserve the right to terminate your account and restrict future access.</p>
          <p>We reserve the right to terminate your access to the Services at any time, for any reason, at our sole discretion, without notice.</p>
          <h3>Reservation of Rights</h3>
          <p>Utilizing Bumpups's services does not grant you ownership or any exclusive rights over any elements of our platform, including usernames or any content such as timestamps or comments generated by us or other users.</p>
          <h3>Evolution, Enhancement, and Modifications</h3>
          <p>Bumpups is committed to the ongoing development and improvement of its services. This may involve changes to the platform, including the addition or removal of features, or the introduction of new functionalities specific to automatic timestamp generation for YouTube videos. We also reserve the right to modify or discontinue our services, either in part or in full, for reasons such as performance optimization, security enhancements, legal compliance, or to counteract unauthorized or abusive use of our platform.</p>
          <p>These changes may impact all users, a subset of users, or even individual accounts. If our services include or require downloadable software, updates to this software may be automatically downloaded and installed on your device, depending on your device settings. While we aim to provide advance notice for significant changes that could negatively affect your use of the service, it may not always be feasible to do so.</p>
          <h3>Modification of Terms</h3>
          <p>As Bumpups is committed to enhancing and refining our services, it may be necessary to update this Agreement periodically. We reserve the right to amend these terms at any time. Should any changes occur, we will notify you through various channels, including but not limited to, posting an announcement on our website at www.bumpups.com, and sending an email to your registered address.</p>
          <p>If you disagree with the updated terms, you have the option to discontinue using our services. Continued usage of Bumpups's services after the effective date of the new terms will be considered as your acceptance of the revised Agreement.</p>
          <h3>Your Rights in the Services</h3>
          <p>The content displayed, generated, or made accessible through Bumpups's services—including but not limited to text, graphics, timestamps, comments, and other user submissions (collectively referred to as "Content")—is protected by copyright and other intellectual property laws. You agree to respect all copyright notices, trademark regulations, and other legal notices contained in any Content you interact with on our platform. You may not use, copy, modify, publish, distribute, or exploit any Content that you do not own without (i) obtaining prior consent from the Content's owner and (ii) ensuring that your actions do not infringe upon anyone else's rights, including Bumpups's.</p>
          <p>We grant each user a worldwide, non-exclusive, non-sublicensable, and non-transferable license to use the Content solely for the purpose of utilizing Bumpups's services. Any use, reproduction, modification, or storage of Content for purposes other than using our services is strictly prohibited unless you have received explicit written permission from us. You acknowledge that Bumpups owns the services and the Content generated by these services. You are not permitted to modify, publish, transmit, participate in the transfer or sale of, or create derivative works based on any part of our services. Even if our platform allows for the downloading or copying of certain Content, all restrictions outlined in this section still apply.</p>
          <h3>User Referral Program</h3>
          <p>Bumpups may occasionally offer rewards or incentives for referring new users to our services. For current referral promotions, please refer to our referral page. The user making the referral ("Referrer") can introduce individuals or entities who are not current Bumpups customers or registered users ("Referee"). A registered user is defined as an individual or entity with an existing Bumpups account. There is no cap on the number of referrals a Referrer can make or the total rewards they can accumulate, unless explicitly stated otherwise. The Referrer will earn the specified reward for each Referee who completes the required action outlined in the specific offer, such as account registration or a particular usage of our services. All Referees must be first-time recipients of the offer, and multiple referrals to the same individual or entity will not be counted.</p>
          <p>Bumpups reserves the right to alter or discontinue any referral offers at any time and to revoke any rewards from both the Referrer and Referee at our discretion for any reason or no reason at all. If we determine that a Referrer or Referee is attempting to abuse the program or violate its terms, we reserve the right to (a) cancel any rewards issued to either party and/or (b) charge the Referrer or Referee for rewards used or issued in violation of these terms. All referral promotions are subject to additional terms, conditions, and restrictions that may be outlined on our platform or presented in conjunction with the specific offer.</p>
          <h1>Your Contributions and Conduct</h1>
          <h3>User-Generated Content</h3>
          <p>Any timestamps, comments, or other data you post, upload, or otherwise contribute through Bumpups's services are considered your "User-Generated Content." Some of this content may be visible to other users. You are solely accountable for all User-Generated Content you contribute to Bumpups. You affirm that all User-Generated Content provided by you is accurate, current, and compliant with this Agreement, as well as all relevant laws and regulations. Bumpups may employ automated systems to analyze User-Generated Content to detect violations and abuse, such as spam or content that infringes upon intellectual property rights.</p>
          <p>You warrant that you will not contribute any User-Generated Content that: (i) violates any third-party intellectual property or other rights (e.g., copyrights, trademarks, privacy); (ii) includes sexually explicit material or pornography; (iii) contains hate speech, defamation, or content that incites hatred or discrimination against individuals or groups; (iv) exploits minors; (v) portrays illegal activities or extreme violence; (vi) depicts cruelty to animals or extreme violence towards animals; (vii) promotes fraudulent or dubious money-making schemes, including multi-level marketing or get-rich-quick schemes; or (viii) is in violation of any applicable laws.</p>
          <h3>Rights You Grant</h3>
          <p>To display your User-Generated Content on Bumpups and make it accessible to other users, you grant us specific rights. All licenses granted are in accordance with our Privacy Policy, especially when they pertain to personally-identifiable information.</p>
          <p>For all User-Generated Content, you grant Bumpups a license to modify (for technical compatibility, for instance, ensuring mobile device compatibility), translate, and reproduce the content. This enables us to provide our services effectively. This is a licensing agreement and does not affect your ownership of the User-Generated Content.</p>
          <p>If you store content in a manner that is only viewable by you ("Private Content"), you grant Bumpups the above licenses, as well as the right to display and distribute your Private Content solely for the purpose of making it accessible to you and providing the necessary services.</p>
          <p>If you share content with a restricted audience ("Restricted Audience Content"), you grant Bumpups the above licenses and the right to display, perform, and distribute your Restricted Audience Content solely for the purpose of making it accessible to the specified users. You also grant those specified users the right to access, use, and exercise all rights in the Restricted Audience Content as permitted by the functionality of our services.</p>
          <p>For content shared publicly or feedback provided to us ("Public Content"), you grant Bumpups the above licenses and the right to display, perform, and distribute your Public Content for the purpose of making it accessible to all Bumpups users. You also grant all Bumpups users the right to access that Public Content and to use and exercise all rights in it, as permitted by the functionality of our services.</p>
          <p>You agree that the licenses you grant are royalty-free, perpetual, irrevocable, sublicensable, and worldwide. Upon account deletion, we will cease to display your User-Generated Content, except for Public Content, which may remain accessible. You acknowledge that complete deletion of content from Bumpups's records may not be possible and that your content may remain viewable elsewhere if copied or stored by other users.</p>
          <p>Certain features allow you to share information through third-party accounts. When content is authorized for sharing, we will identify it clearly. If you share information through third-party accounts, you authorize Bumpups to share that information with the third-party account provider. Please review their policies for additional information on how they may use your data.</p>
          <p>Lastly, you understand that Bumpups may need to make technical adjustments to your User-Generated Content to meet the technical requirements of various networks, devices, or services. The licenses you grant us include the rights to make such adjustments.</p>
          <h3>Copyright Protection Measures</h3>
          <p>In compliance with the Digital Millennium Copyright Act (DMCA), Bumpups has established the following procedures for addressing copyright infringement. We reserve the right to (1) restrict access to or remove content that we, in good faith, believe to be copyrighted material unlawfully copied and distributed by any user, affiliate, or content provider on our platform, and (2) terminate the accounts of repeat infringers.</p>

          <h4>Reporting Copyright Infringements</h4>
          <p>If you believe that content accessible through Bumpups's services infringes upon your copyright or a copyright you are authorized to represent, please send a notice containing the following information to Bumpups's Designated Agent for Receiving Notification of Claimed Infringement.</p>

          <h4>Actions Upon Receiving Proper Infringement Notification</h4>
          <p>Upon receiving a valid copyright infringement notice, Bumpups reserves the right to:</p>
          <ul>
            <li>Remove or disable access to the infringing material;</li>
            <li>Inform the accused content provider that the material has been removed or access to it has been disabled;</li>
            <li>Terminate the account of repeat infringers.</li>
          </ul>

          <h4>Counter-Notice Procedure</h4>
          <p>If you believe that the removed material is not infringing or that you have the right to post it, you may send a counter-notice containing specific information to the Designated Agent.</p>

          <p>Please contact Bumpups's Designated Agent at the following address:</p>
          <p>Bumpups Software LLC</p>
          <p>Attn: DMCA Designated Agent</p>
          <p>5473 Blair Rd Ste 100 #112725, Dallas, TX 75231-4227</p>
          <h3>Accountability for Content and Interactions on Bumpups</h3>
          <p>All information or content, whether publicly posted or privately transmitted via Bumpups, is the exclusive responsibility of the individual from whom it originates. You engage with such content at your own risk, and Bumpups is not liable for any errors, omissions, or any losses or damages you may incur due to this content.</p>

          <p>We have no obligation to monitor or control how you interpret or utilize the content, and we are not responsible for verifying the identity of users you may interact with on Bumpups. You are solely responsible for all content you contribute to Bumpups and warrant that you have all necessary rights to do so.</p>

          <p>Bumpups may contain links to third-party websites or services that are not owned or controlled by us. Accessing these third-party sites comes with its own risks, for which Bumpups is not responsible.</p>

          <p>Your interactions with organizations or individuals found on or through Bumpups, including transactions and any associated terms, conditions, warranties, or representations, are solely between you and such organizations or individuals. Bumpups is not liable for any loss or damage incurred as a result of such interactions.</p>

          <p>If disputes arise between you and other users or third parties, Bumpups is under no obligation to become involved. By using Bumpups, you release us from any and all liability related to such disputes.</p>
          <h3>Account Suspension & Termination</h3>

          <h4>Termination by User</h4>
          <p>You are free to discontinue using Bumpups's services at any time. To do so, follow the provided instructions to delete your Bumpups account, which will also remove all your stored data. You have the option to download a copy of your data before proceeding with the account deletion.</p>

          <h4>Termination and Suspension by Bumpups</h4>
          <p>Bumpups reserves the right to suspend or terminate your account and access to our services for any reason at our discretion, including but not limited to, violations of this Agreement. We hold the exclusive right to determine whether you have breached any terms or conditions outlined in this Agreement.</p>

          <h4>Consequences of Account Suspension or Termination</h4>
          <p>Terminating your account may result in the deletion of any content associated with your account, such as automatically generated timestamps for your YouTube videos. While we aim to provide advance notice before terminating your account to allow you to back up any important data, we may not be able to do so in certain circumstances, such as if it would be illegal, compromise someone's safety or security, or harm Bumpups's rights or property.</p>
          Bumpups
          <p>If you accidentally delete your Bumpups account, contact us immediately at <a href="mailto:contact@bumpups.com" class="policyLinks">contact@bumpups.com</a>. We will attempt to assist you, but we cannot guarantee the recovery or restoration of any data.</p>

          <p>Sections of this Agreement that are intended to survive termination will continue to be in effect. For example, any obligations you have to compensate or indemnify us, limitations on our liability, and terms concerning ownership, intellectual property rights, and dispute resolution will survive account termination.</p>
          <h3>Fees and Payments</h3>

          <h4>Free and Paid Services</h4>
          <p>Bumpups may offer both free and paid services. If you are using a service that is initially free, we will notify you before introducing any fees for that service. To continue using the service, you will be required to pay the applicable fees.</p>

          <p>Please note that if you opt to receive text messages from Bumpups, additional data and message rates may apply, and you are solely responsible for such charges. Consult your wireless carrier for details.</p>

          <h4>Paid Services</h4>
          <p>Some Bumpups services may require payment either now or in the future ("Paid Services"). For details on our current Paid Services, please refer to our Paid Services page. The payment terms presented during the sign-up process for any Paid Service are considered part of this Agreement.</p>

          <h4>Billing</h4>
          <p>We utilize a third-party payment processor, currently Stripe, Inc., to handle billing for Paid Services. Payments are processed according to Stripe's terms, conditions, and privacy policies, in addition to this Agreement. You can review Stripe's Terms of Service <a href="https://stripe.com/us/checkout/legal" class="policyLinks">here</a> and their Privacy Policy <a href="https://stripe.com/us/privacy" class="policyLinks">here</a>. Bumpups is not liable for any errors, acts, or omissions by Stripe.</p>

          <p>By opting for Paid Services, you authorize us to charge your payment method through Stripe for all applicable fees. You agree to make all payments using your selected payment method and understand that we reserve the right to correct any errors made by Stripe, even if payment has already been requested or received.</p>
          <h3>Payment Method</h3>
          <p>Your payment terms are determined by your chosen Payment Method and may be governed by agreements between you and your financial institution or credit card issuer. If payment is not successfully processed through our Payment Processor, you are responsible for paying all amounts owed on your Billing Account immediately upon demand.</p>

          <h3>Recurring Billing</h3>
          <p>Some of our Paid Services may have an initial one-time charge followed by recurring charges, as agreed upon by you. By opting for a recurring payment plan, you understand and accept that you are responsible for all recurring charges until you cancel the service. WE WILL CONTINUE TO INITIATE PERIODIC CHARGES (E.G., MONTHLY) WITHOUT ADDITIONAL AUTHORIZATION FROM YOU UNTIL YOU NOTIFY US AND WE CONFIRM RECEIPT OF YOUR NOTICE THAT YOU WISH TO TERMINATE THIS AUTHORIZATION OR CHANGE YOUR PAYMENT METHOD. SUCH TERMINATION OR CHANGE WILL NOT AFFECT CHARGES MADE BEFORE WE COULD REASONABLY ACT TO IMPLEMENT THE CHANGE.</p>
          <h3>Up-to-Date Billing Information</h3>
          <p>It's crucial that you maintain accurate and current information for your Billing Account. Any changes, such as updates to your billing address, credit card number, or expiration date, should be made promptly in your account settings. If your payment method is canceled for reasons like loss or theft, or if you become aware of a security breach like unauthorized use of your username or password, you must notify us or our Payment Processor immediately. Failure to keep your Billing Account updated may result in continued charges for Paid Services, unless you've terminated those services as outlined in this Agreement.</p>

          <h3>Variation in Preauthorized Amounts</h3>
          <p>If the charge to your Billing Account differs from the preauthorized amount (excluding changes due to state sales taxes), you have the right to receive notice of the charge amount and date before the transaction occurs. Your agreement with your payment provider will dictate the terms of your Payment Method. We may accumulate charges and submit them as one or more aggregate charges during or at the end of each billing cycle.</p>

          <h3>Auto-Renewal of Paid Services</h3>
          <p>Unless you disable auto-renewal in your account settings, your Paid Services will automatically renew for periods equal to your original subscription term, at the current non-promotional rate. To modify or cancel your Paid Services, visit your account settings. If you cancel a Paid Service, you can use it until the end of your current term, but it will not renew thereafter. No prorated refunds will be issued for the current subscription period. TO AVOID RECURRING CHARGES, YOU MUST CANCEL THE RELEVANT PAID SERVICES OR TERMINATE YOUR BUMPUPS ACCOUNT BEFORE THE END OF THE CURRENT TERM. NO REFUNDS WILL BE ISSUED FOR FEES ALREADY PAID, UNLESS SPECIFICALLY STATED IN THIS AGREEMENT.</p>
          <h3>Continued Authorization for Paid Services</h3>
          <p>By not terminating or continuing to use a Paid Service, you confirm our authorization to charge your Payment Method for that particular service. We may proceed with these charges, for which you'll be responsible. This doesn't limit our ability to seek payment from you directly. The billing structure for your charges could be upfront, in arrears, per usage, or as initially outlined when you chose to use the Paid Service.</p>

          <h3>Free Trials and Special Offers</h3>
          <p>If you're using a free trial or other promotional offer that grants access to a Paid Service, make sure to use it within the time frame specified for the trial. To avoid being billed, you must discontinue the Paid Service before the trial period ends. If you're mistakenly charged for a Paid Service after canceling during the trial period, please reach out to us at contact@bumpups.com.</p>
          <h3>Disclaimer of Warranties</h3>
          <p>Bumpups, along with its licensors, suppliers, and affiliates, and each of their respective officers, directors, and employees (collectively, the "Bumpups Parties"), make no guarantees or warranties about the Services or any Content accessed through the Services. The Bumpups Parties are not responsible for the accuracy, compliance with copyright laws, legality, or decency of any content accessed through the Services. The Services and Content are provided "as-is," without any express or implied warranties, including but not limited to warranties of merchantability, fitness for a particular purpose, or non-infringement. Use of the Services is at your own risk and is not guaranteed to be uninterrupted or error-free.</p>

          <h3>Limitation of Liability</h3>
          <p>To the maximum extent permitted by law, under no legal theory shall the Bumpups Parties be liable for any indirect, special, incidental, punitive, or consequential damages, including but not limited to loss of profits, data, or goodwill. The aggregate liability for Bumpups shall not exceed the greater of $100 or the amount you have paid to Bumpups in connection with the Services in the last twelve months.</p>

          <h3>Indemnification</h3>
          <p>You agree to defend, indemnify, and hold harmless the Bumpups Parties from any claims, liabilities, damages, losses, and expenses, including legal fees, arising from or related to your use of the Services or any breach of this Agreement. We will make reasonable efforts to notify you of any such claim, action, or proceeding upon becoming aware of it.</p>
          <h3>External Links</h3>
            <p>The Services may include links to external sites or services that Bumpups does not own or control. Bumpups is not responsible for the content, terms, or privacy policies of these external sites. We recommend that you review the terms and privacy policies of any third-party sites you visit.</p>

            <h3>Transfer of Agreement</h3>
            <p>You are not permitted to transfer, assign, or delegate this Agreement, your rights, or your Bumpups Account without prior written approval from Bumpups. Bumpups reserves the right to assign this Agreement without your consent.</p>

            <h3>Severability</h3>
            <p>If any provision of this Agreement is found to be unenforceable, it will not affect the enforceability of the remaining provisions.</p>

            <h3>Non-Waiver</h3>
            <p>Failure by Bumpups to enforce any provision of this Agreement immediately does not constitute a waiver of our rights to enforce it in the future.</p>

            <h3>Interpretation</h3>
            <p>When the terms "include" or "including" are used in this Agreement, they mean "including but not limited to," and any examples are for illustrative purposes only.</p>

            <h3>Applicable Law</h3>
            <p>This Agreement is governed by the Federal Arbitration Act, federal laws, and Texas state laws, without regard to conflict of law principles.</p>

            <h3>Time Limit for Legal Claims</h3>
            <p>You and Bumpups agree that any legal action related to the Services must begin within one (1) year after the cause of action arises; otherwise, the action is permanently barred.</p>

            <h3>Arbitration Agreement</h3>
              <p>Please read this section carefully as it mandates arbitration for resolving disputes between you and Bumpups, and limits how you can seek relief from us. By agreeing to these terms, you and Bumpups recognize that Bumpups's Personnel are third-party beneficiaries of this Agreement. Upon your acceptance, such Personnel have the right to enforce this Agreement against you.</p>

              <h4>(a) Pre-Arbitration Negotiation</h4>
              <p>Both parties commit to resolving any disputes through good-faith negotiations before initiating arbitration. If unresolved, the dispute will proceed to binding arbitration in Dallas County, Texas, following the JAMS Streamlined Arbitration Rules and Procedures. The arbitration will be conducted in English by an arbitrator experienced in intellectual property and commercial contract disputes.</p>

              <h4>(b) Small Claims and Intellectual Property</h4>
              <p>Either party may bring qualifying claims in small claims court in Dallas County, Texas. Both parties retain the right to seek injunctive or other equitable relief from a competent court to prevent intellectual property infringement.</p>

              <h4>(c) Waiver of Jury Trial</h4>
              <p>Both parties waive the right to a jury trial, opting for arbitration as the exclusive dispute resolution method. Arbitration is generally quicker, more efficient, and less expensive than a court trial.</p>

              <h4>(d) No Class Actions</h4>
              <p>All disputes must be resolved on an individual basis. Class actions or consolidated claims are not permitted. If this clause is deemed unenforceable, neither party is entitled to arbitration and must resolve the dispute in court.</p>

              <h4>(e) Opt-Out Option</h4>
              <p>You may opt out of this Arbitration Agreement within 30 days of first accepting these terms by sending a written notice to 5473 Blair Rd Ste 100 #112725, Dallas, TX 75231-4227. The notice must include your name, address, account-associated email or phone number, and a clear statement of your intent to opt out.</p>

              <h4>(f) Court Venue</h4>
              <p>If you opt out or if arbitration is not enforceable, any judicial proceedings will occur in state or federal courts in Dallas County, Texas.</p>

              <h4>(g) Severability</h4>
              <p>If any part of this Arbitration Agreement is found to be unenforceable, the remaining portions will remain in effect. This Arbitration Agreement will continue to apply even if your relationship with Bumpups ends.</p>

              <h3>Miscellaneous</h3>
              <p>You are solely responsible for all tax-related obligations arising from your use of the Services, although Bumpups reserves the right to handle any of these duties either for you or for itself as it deems appropriate. This Agreement represents the entire understanding between you and Bumpups and supersedes all prior agreements and communications, whether oral or written, regarding the subject matter of this Agreement.</p>

        </div>

     
     
     
     
     </div>
      <Footer />
    </div>
  );
};

export default TermsOfService;