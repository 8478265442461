// src/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import { auth } from './firebase'; // Ensure correct path
import { onAuthStateChanged } from 'firebase/auth';

// Create the context
export const AuthContext = createContext();

// Create the provider component
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    // console.log("AuthProvider mounted, setting up onAuthStateChanged listener");

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // console.log("User logged in:", user);

        setCurrentUser(user);
        const metadata = user.metadata;
        const isNewUser = metadata.creationTime === metadata.lastSignInTime;
        // console.log("Is new user:", isNewUser);

        if (isNewUser) {
          const signUpSentKey = `signUpEventSent_${user.uid}`;
          const signUpSent = localStorage.getItem(signUpSentKey);

          if (!signUpSent) {
            // Determine sign-up method
            let method = 'email'; // default
            if (user.providerData.length > 0) {
              const providerId = user.providerData[0].providerId;
              if (providerId.includes('google')) {
                method = 'google';
              } else {
                method = providerId;
              }
            }
            // console.log("Sign-up method detected:", method);

            // Get gclid from localStorage
            const gclid = localStorage.getItem('gclid') || '';
            // console.log("gclid retrieved from localStorage:", gclid);

            // Send GA4 sign_up event
            sendSignUpEvent(user.uid, gclid, method);

            // Set the flag in localStorage to prevent duplicate events
            try {
              localStorage.setItem(signUpSentKey, 'true');
              // console.log(`Sign-up event flag set in localStorage for user ${user.uid}`);
            } catch (error) {
              // console.error("Error setting sign-up flag in localStorage:", error);
            }
          } else {
            // console.log(`Sign-up event already sent for user ${user.uid} in this browser`);
          }
        } else {
          // console.log(`Returning user: ${user.uid}`);
        }
      } else {
        // console.log("User logged out");
        setCurrentUser(null);
      }
    });

    // Cleanup the listener on component unmount
    return () => {
      // console.log("Cleaning up onAuthStateChanged listener");
      unsubscribe();
    };
  }, []);

  const sendSignUpEvent = (userId, gclid, method = 'email') => {
    if (window.gtag) {
      window.gtag('event', 'sign_up', {
        method: method,   // e.g., 'email', 'google'
        gclid: gclid,     // Include gclid if available
        user_id: userId,  // Optional: Send user ID
      });
      // console.log(`GA4 sign_up event sent for user ${userId} with gclid: "${gclid}" and method: "${method}"`);
    } else {
      // console.error('gtag is not defined');
    }
  };

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};
