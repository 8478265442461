// src/components/SubPlanSection.js

import React from 'react';
import './SubPlanSection.css';
import basePlanLogo from '../assets/images/baseplan-white.png';
import bumpPlanLogo from '../assets/images/bumpups-white.png';

import UserPlanPopup from '../myPlans/UserPlanPopup';
import UserPaidPopup from '../myPlans/UserPaidPopup';

const SubPlanSection = ({
  isActive,
  currentPlan,
  timeBank,
  timeBankBump,
  showPlanPopUp,
  setShowPlanPopUp,
  billingEmail,
  price,
  totalPlanTime,
  renewalDate,
  openStripeBilling,
  openContactPage
}) => {

  return (
    <div className="combined-plan-box">
      
      {/* Subscription Details Section */}
      <div className="subscription-details">

        <div className="plan-title">
          {(currentPlan === "base" || currentPlan === "bump" || currentPlan === "Essential Plan") && (
            <img 
              src={currentPlan === "base" || currentPlan === "Essential Plan" ? basePlanLogo : bumpPlanLogo} 
              alt={currentPlan === "base" || currentPlan === "Essential Plan" ? "Base Plan Logo" : "Bump Plan Logo"} 
              className="bumpplan-logo" 
            />
          )}
          {currentPlan === "base" || currentPlan === "Essential Plan" ? "Base Plan" : currentPlan === "bump" ? "Bump Plan" : currentPlan || "Loading..."}
        </div>

        <div className={`active-badge ${isActive ? "" : "active-badge-canceled"}`}>
          {isActive !== null ? (isActive ? "Active" : "Canceled") : "-----"}
        </div>
      </div>

      {/* Separator Line */}

      {/* Subscription Details Content */}
      <div className="subscription-details-content">
        <div className="plan-details">
          <div className="upload-time-container">
            {currentPlan === "Free Plan" && (
              <div className="upload-time-wrapper">
                <span className="upload-credits-label">Credits</span>
                <span className="upload-credits-time" style={{ color: '#ffbf00' }}>
                  {timeBank ? `${Math.floor(timeBank / 60)}h ${timeBank % 60}m` : "0m"}
                </span>
              </div>
            )}

            {(currentPlan === "base" || currentPlan === "bump" || currentPlan === "Essential Plan") && (
              <>
                {(currentPlan === "base" || currentPlan === "Essential Plan") ? (
                  <>
                    <div className="upload-time-wrapper">
                      <div className="upload-credits-label-with-logo">
                        <img src={basePlanLogo} alt="Base Plan Logo" className="plan-logo" />
                        <span className="upload-credits-label">Base credits</span>
                      </div>
                      <div className="upload-credits-time-with-icon">
                        <span className="upload-credits-time base-plan-time">
                          {timeBank ? (
                            <>
                              <span className="time-value">{`${Math.floor(timeBank / 60)}h ${timeBank % 60}m`}</span>
                            </>
                          ) : "0m"}
                        </span>
                      </div>
                    </div>

                    <div className="upload-time-wrapper">
                      <div className="upload-credits-label-with-logo">
                        <img src={bumpPlanLogo} alt="Bump Plan Logo" className="plan-logo" />
                        <span className="upload-credits-label">Bump credits</span>
                      </div>
                      <div className="upload-credits-time-with-icon">
                        <span className="upload-credits-time bump-plan-time">
                          {timeBankBump ? (
                            <>
                              <span className="time-value">{`${Math.floor(timeBankBump / 60)}h ${timeBankBump % 60}m`}</span>
                            </>
                          ) : <span style={{ color: '#4add80' }}>0h 0m</span>}
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="upload-time-wrapper">
                      <div className="upload-credits-label-with-logo">
                        <img src={bumpPlanLogo} alt="Bump Plan Logo" className="plan-logo" />
                        <span className="upload-credits-label">Bump credits</span>
                      </div>
                      <div className="upload-credits-time-with-icon">
                        <span className="upload-credits-time bump-plan-time">
                          {timeBankBump ? (
                            <>
                              <span className="time-value">{`${Math.floor(timeBankBump / 60)}h ${timeBankBump % 60}m`}</span>
                            </>
                          ) : <span style={{ color: '#4add80' }}>0h 0m</span>}
                        </span>
                      </div>
                    </div>

                    <div className="upload-time-wrapper">
                      <div className="upload-credits-label-with-logo">
                        <img src={basePlanLogo} alt="Base Plan Logo" className="plan-logo" />
                        <span className="upload-credits-label">Base credits</span>
                      </div>
                      <div className="upload-credits-time-with-icon">
                        <span className="upload-credits-time base-plan-time">
                          {timeBank ? (
                            <>
                              <span className="time-value">{`${Math.floor(timeBank / 60)}h ${timeBank % 60}m`}</span>
                            </>
                          ) : "0m"}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>

        <p className="rebuy-text rebuy-text-spacing">
          Running out of upload credits earlier? Rebuy a new plan and unused credits will carry over.
        </p>
        <button
          className="add-more-btn"
          onClick={() => setShowPlanPopUp(true)}
        >
          {(currentPlan === "base" || currentPlan === "Essential Plan" || currentPlan === "bump") && !isActive ? 
            "Renew Plan" : 
            (currentPlan === "base" || currentPlan === "Essential Plan" || currentPlan === "bump") ? 
            "Add more or change" : 
            "Upgrade Plan"}
        </button>
        {showPlanPopUp && (
          (currentPlan === "base" || currentPlan === "Essential Plan" || currentPlan === "bump") && isActive ?
            <UserPaidPopup
              isOpen={showPlanPopUp}
              onClose={() => setShowPlanPopUp(false)}
              currentPlan={currentPlan}
            /> :
            <UserPlanPopup
              isOpen={showPlanPopUp}
              onClose={() => setShowPlanPopUp(false)}
              currentPlan={currentPlan}
            />
        )}
      </div>

      {/* Separator Line */}
      <div className="separator"></div>

      {/* Billing & Payment Section */}
      <div className="billing-details">
        <h2 className="bulling-plan-title">Billing & Payment</h2>
        {billingEmail && (
          <div className="plan-details">
            <span className="billing-label">Email</span>
            <span className="billing-info-email">{billingEmail}</span>
          </div>
        )}
        <div className="plan-details">
          <span className="billing-label">Price</span>
          <span className="billing-info">
            {price !== null && price !== undefined ? `$${price.toFixed(2)} USD/month` : "Loading..."}
          </span>
        </div>
        {(currentPlan === "base" || currentPlan === "bump" || currentPlan === "Essential Plan") && (
          <>
            <div className="plan-details">
              <span className="billing-label">Plan Credits</span>
              <span 
                className={`billing-info ${currentPlan === "bump" ? "plan-time-bump" : (currentPlan === "base" || currentPlan === "Essential Plan") ? "plan-time-base-essential" : ""}`}
              >
                {totalPlanTime || "Loading..."}m
              </span>
            </div>
            <div className="plan-details">
              <span className="billing-label">Billing period</span>
              <span className="billing-info">Monthly</span>
            </div>
            <div className="plan-details">
              <span className="billing-label">
                {isActive !== null ? (isActive ? "Renewal date" : "Expiration date") : "Loading..."}
              </span>
              <span className={`billing-info ${!isActive ? "red-text" : ""}`}>
                {renewalDate || "Loading..."}
              </span>
            </div>
          </>
        )}
        <button className="invoice-update-btn" onClick={openStripeBilling}>
          Invoice & Update billing info
        </button>     
        <button className="invoice-update-btn" onClick={openContactPage}>
          Contact us
        </button>
      </div>
      
    </div>
  );
};

export default SubPlanSection;
