// src/components/PurchaseLog.js

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './PurchaseLog.css';

const PurchaseLog = ({ purchaseHistory }) => {
  // Local state for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 10; // Updated from 3 to 10

  // Calculate total pages based on purchaseHistory length
  const totalPages = Math.ceil(purchaseHistory.length / entriesPerPage);

  // Determine the entries to display on the current page
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = purchaseHistory.slice(indexOfFirstEntry, indexOfLastEntry);

  // Handlers for pagination buttons
  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  // Mapping objects for display values and CSS classes
  const planTypeDisplayMap = {
    base: 'Base',
    bump: 'Bump',
  };

  const purchaseTypeDisplayMap = {
    one_time: 'One Time',
    subscription: 'Plan',
  };

  const badgeClassMap = {
    base: 'purlog-badge-base',
    bump: 'purlog-badge-bump',
    default: 'purlog-badge-default',
  };

  const typeBadgeClassMap = {
    one_time: 'purlog-badge-type-grey',
    subscription: 'purlog-badge-type-grey',
    default: 'purlog-badge-type-default',
  };

  const creditsClassMap = {
    base: 'purlog-credits-base',
    bump: 'purlog-credits-bump',
    default: 'purlog-credits-default',
  };

  // Optional: Reset to first page if purchaseHistory changes
  useEffect(() => {
    setCurrentPage(1);
  }, [purchaseHistory]);

  return (
    <div className="purlog-purchase-log-box">
      <h2 className="purlog-title">Purchase History</h2>

      <div className="purlog-header-row">
        <div className="purlog-header-col">Credits</div>
        <div className="purlog-header-col">Tier</div>
        <div className="purlog-header-col">Type</div>
        {/* Added a specific class 'purlog-date-col' */}
        <div className="purlog-header-col purlog-date-col">Date</div>
      </div>

      {currentEntries.length === 0 ? (
        <p className="purlog-no-purchases">No purchases found.</p>
      ) : (
        currentEntries.map((purchase) => {
          // Destructure purchase data with default values
          const {
            id,
            plan_type = 'default',
            purchase_type = 'default',
            total_minutes = 0,
            purchase_datetime = 'N/A',
          } = purchase;

          // Determine display values
          const displayTier = planTypeDisplayMap[plan_type.toLowerCase()] || capitalize(plan_type);
          const displayPurchaseType =
            purchaseTypeDisplayMap[purchase_type.toLowerCase()] || capitalize(purchase_type);

          // Determine CSS classes based on backend values
          const tierClass = badgeClassMap[plan_type.toLowerCase()] || badgeClassMap['default'];
          const typeClass = typeBadgeClassMap[purchase_type.toLowerCase()] || typeBadgeClassMap['default'];
          const creditsClass = creditsClassMap[plan_type.toLowerCase()] || creditsClassMap['default'];

          return (
            <div className="purlog-data-row" key={id}>
              <div className={`purlog-data-col purlog-credits ${creditsClass}`}>
                {total_minutes}
              </div>

              <div className="purlog-data-col purlog-tier">
                <span className={tierClass}>{displayTier}</span>
              </div>

              <div className="purlog-data-col purlog-type">
                <span className={typeClass}>{displayPurchaseType}</span>
              </div>

              {/* Ensure the Date column has the 'purlog-date' class */}
              <div className="purlog-data-col purlog-date">
                {purchase_datetime}
              </div>
            </div>
          );
        })
      )}

      {totalPages > 1 && (
        <div className="purlog-pagination-controls">
          <button
            className="purlog-pagination-button"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            aria-label="Previous Page"
          >
            <span className="purlog-arrow purlog-left"></span>
          </button>
          <span className="purlog-pagination-info">
            Page {currentPage} of {totalPages}
          </span>
          <button
            className="purlog-pagination-button"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            aria-label="Next Page"
          >
            <span className="purlog-arrow purlog-right"></span>
          </button>
        </div>
      )}
    </div>
  );
};

// Helper function to capitalize the first letter
const capitalize = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

PurchaseLog.propTypes = {
  purchaseHistory: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      plan_type: PropTypes.string,
      purchase_type: PropTypes.string,
      total_minutes: PropTypes.number,
      purchase_datetime: PropTypes.string,
    })
  ).isRequired,
};

export default PurchaseLog;
