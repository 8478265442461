// ./homeComponent/socialprofileCarousel.js
import React from 'react';
import './socialprofileCarousel.css';

// Images
import utubeLogo from '../../assets/images/v2-creator.png';
import AquinasYT from '../../assets/images/Aquinas-YT.jpg';
import cbYT from '../../assets/images/corbinbrown-yt.jpg';
import FIGGMUNITYT from '../../assets/images/FIGGMUNITY-YT.jpg';
import RottenMango from '../../assets/images/RottenMango.jpg';
import ComeAndTalk from '../../assets/images/ComeAndTalk.jpg';
import Valuetainment from '../../assets/images/Valuetainment.jpg';

const profiles = [
  { img: AquinasYT, name: "Pints With Aquinas", subscribers: "546K" },
  { img: cbYT, name: "Corbin Brown", subscribers: "61K" },
  { img: FIGGMUNITYT, name: "FIGGMUNITY WORLD", subscribers: "148K" },
  { img: RottenMango, name: "Rotten Mango", subscribers: "2.8M" },
  { img: ComeAndTalk, name: "Come And Talk 2 Me", subscribers: "592K" },
  { img: Valuetainment, name: "Valuetainment", subscribers: "5.6M" }
];

function SocialProfileCarousel() {
  return (
    <div className="spc-scrolling-bar-container-wrapper">
      <div className="spc-scrolling-bar-container">
        <div className="spc-scrolling-bar">
          {Array.from({ length: 12 }).flatMap((_, index) =>
            profiles.map((profile, profileIndex) => (
              <div key={`${index}-${profileIndex}`} className="spc-profile">
                <div className="spc-profile-img-wrapper">
                  <img src={profile.img} alt={`${profile.name} Profile`} className="spc-profile-img"/>
                  <img src={utubeLogo} alt="YouTube Logo" className="spc-youtube-logo"/>
                </div>
                <p className="spc-profile-name">{profile.name}</p>
                <p className="spc-profile-subscribers">{profile.subscribers}</p>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default SocialProfileCarousel;
