import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './BrandTemplate.css';

//FIREBASE
import { db, auth } from '../firebase';
import { doc, setDoc, onSnapshot } from 'firebase/firestore';

//FUNCTIONS
import { debounce } from 'lodash';

//IMAGES
import placeholderImage from '../assets/images/placeholder-load.jpg';



const BrandTemplate = () => {


  const user = auth.currentUser;
  const defaultFixedText = 'Hey everyone!\nHere are important parts of our video:';
  const localStorageData = user ? sessionStorage.getItem(`userData_${user.uid}`) : null;
  const userData = localStorageData ? JSON.parse(localStorageData) : {};
  
  // Initialize state with localStorage values if they exist, or set default values
  const [correctWords, setCorrectWords] = useState(userData.correctWordDoc?.correctWords || '');
  const [fixedText, setFixedText] = useState(userData.brandingData?.fixedText || defaultFixedText);
  const [profileImage, setProfileImage] = useState(userData.profileImage || '');
  const [branding, setBranding] = useState(userData.brandingData?.isBrandingEnabled !== undefined ? userData.brandingData.isBrandingEnabled : true);
  



  const [currentPlan, setCurrentPlan] = useState(userData.subscriptionData?.currentPlan || 'Free Plan');


  const [numberTimestamps, setNumberTimestamps] = useState(7);
  const [textError, setTextError] = useState(null);
  const [wordsError, setWordsError] = useState(null);
  const [isSaved, setIsSaved] = useState(false);


  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const userDocRef = doc(db, 'users', user.uid);
      
      const timestampProfileRef = doc(userDocRef, 'valueSettings', 'timestamps');

      const subscriptionRef = doc(userDocRef, 'subscriptionData', 'planDetails');


      const profileCorrectRef = doc(userDocRef, 'userSettings', 'profile');

  
      // Attempt to load data from localStorage first
      const localStorageData = sessionStorage.getItem(`userData_${user.uid}`);
      if (localStorageData) {
        // console.log("Loading data from localStorage");
        const { brandingData, subscriptionData, profileImage, correctWordDoc } = JSON.parse(localStorageData);
        setCorrectWords(correctWordDoc?.correctWords || '');


        setFixedText(brandingData?.fixedText || 'Hey Everyone 👋\nFind the parts that interest you:');
        setCurrentPlan(subscriptionData?.currentPlan || 'Free Plan');
        setBranding(brandingData?.isBrandingEnabled !== undefined ? brandingData.isBrandingEnabled : true);
        setProfileImage(profileImage);
      } else {
        // console.log("No data found in localStorage, setting up Firestore listeners");
      }


      // Real-time listener for brandingProfile
      const unsubscribeBranding = onSnapshot(timestampProfileRef, (doc) => {
        if (doc.exists()) {
          const brandingData = doc.data();
          setFixedText(brandingData.fixedText || 'Hey Everyone 👋\nFind the parts that interest you:');
          setBranding(brandingData.isBrandingEnabled !== undefined ? brandingData.isBrandingEnabled : true);
          // Update localStorage
          sessionStorage.setItem(`userData_${user.uid}`, JSON.stringify({ brandingData, subscriptionData: JSON.parse(localStorageData)?.subscriptionData, profileImage: JSON.parse(localStorageData)?.profileImage }));
        }
      });
  
  
      // Real-time listener for subscriptionData
      const unsubscribeSubscription = onSnapshot(subscriptionRef, (doc) => {
        if (doc.exists()) {
          const subscriptionData = doc.data();
          setCurrentPlan(subscriptionData.currentPlan || 'Free Plan');
          // Update localStorage
          sessionStorage.setItem(`userData_${user.uid}`, JSON.stringify({ brandingData: JSON.parse(localStorageData)?.brandingData, subscriptionData, profileImage: JSON.parse(localStorageData)?.profileImage }));
        }
      });
  
      // Real-time listener for profile image using the new path
      const unsubscribeUser = onSnapshot(profileCorrectRef, (doc) => {
        if (doc.exists()) {
          const profileImageData = doc.data().profileImage; // Continue using 'profileImage' key if it's still the correct one
          setProfileImage(profileImageData);
          // Update localStorage with the new profile image path data
          sessionStorage.setItem(`userData_${user.uid}`, JSON.stringify({
            brandingData: JSON.parse(localStorageData)?.brandingData,
            subscriptionData: JSON.parse(localStorageData)?.subscriptionData,
            profileImage: profileImageData
          }));
        }
      });
      // Real-time listener for fixed text data
      const unsubscribeCorrectData = onSnapshot(profileCorrectRef, (doc) => {
        if (doc.exists()) {
          const correctWordDoc = doc.data(); // Renamed variable here
          // Update fixedText based on the new path's data
          setCorrectWords(correctWordDoc.correctWords || '');

          
          // Update localStorage with the latest fixedText while preserving other data
          const localStorageDataParsed = JSON.parse(sessionStorage.getItem(`userData_${user.uid}`)) || {};
          sessionStorage.setItem(`userData_${user.uid}`, JSON.stringify({
            ...localStorageDataParsed,
            fixedTextData: { ...localStorageDataParsed.correctWordDoc, correctWords: correctWordDoc.correctWords } // Renamed variable here
          }));
        }
      });


      // Cleanup function to unsubscribe from Firestore listeners when the component unmounts
      return () => {
        unsubscribeBranding();
        unsubscribeSubscription();
        unsubscribeUser();
        unsubscribeCorrectData();
      };
    }
  }, []); // Dependencies array is empty, so this effect runs only once on mount
  
  
    // New useEffect to reset isSaved state
    useEffect(() => {
        setIsSaved(false);
      }, [numberTimestamps, fixedText, correctWords]);
    

  const generateSampleTimestamps = () => {
    let sampleTimestamps = [];
    const sampleTexts = [
      { time: "01:05", text: "Why timestamps are crucial on YouTube" },
      { time: "04:12", text: "Psychological aspects of engaging users with timestamps" },
      { time: "07:23", text: "How timestamps boost video SEO rankings" },
      { time: "11:37", text: "Comparing videos with and without timestamps - a case study" },
      { time: "16:49", text: "Crafting effective timestamps - best practices" },
      { time: "22:26", text: "Tools and software for automating timestamps" },
      { time: "28:08", text: "Mistakes to avoid when creating timestamps" },
      { time: "33:33", text: "Updating older videos with retroactive timestamps" },
      { time: "39:47", text: "How timestamps contribute to audience retention" },
      { time: "44:55", text: "Leveraging timestamps for YouTube channel growth" }
    ];
    for (let i = 0; i < numberTimestamps; i++) {
      const sample = sampleTexts[i] || { time: "00:00", text: "concise description." };
      sampleTimestamps.push(
        <div key={i}>
          <span className="timestamp-time">{sample.time}</span> - <span className="timestamp-text">{sample.text}</span>
        </div>
      );
    }
    return sampleTimestamps;
  };
  
  









// Wrap your save function with debounce to prevent rapid successive calls
const debouncedSaveToFirestore = debounce(async () => {
  const user = auth.currentUser;
  const timestampTempDoc = doc(db, 'users', user.uid, 'valueSettings', 'timestamps');
  const correctWordsTempDoc = doc(db, 'users', user.uid, 'userSettings', 'profile');

  // Update Firestore documents
  await setDoc(timestampTempDoc, {
    "fixedText": fixedText,
    "isBrandingEnabled": branding
  }, { merge: true });

  await setDoc(correctWordsTempDoc, {
    "correctWords": correctWords,
  }, { merge: true });

  // Update sessionStorage to keep it in sync with Firestore
  const sessionStorageData = JSON.parse(sessionStorage.getItem(`userData_${user.uid}`)) || {};
  sessionStorageData.fixedTextData = { ...sessionStorageData.fixedTextData, fixedText: fixedText };
  sessionStorageData.brandingData = { ...sessionStorageData.brandingData, isBrandingEnabled: branding };
  sessionStorageData.correctWords = { ...sessionStorageData.correctWordDoc, correctWords: correctWords };

  sessionStorage.setItem(`userData_${user.uid}`, JSON.stringify(sessionStorageData));

  setIsSaved(true);
  setTimeout(() => {
    setIsSaved(false);
  }, 1500);
}, 700); // 300ms delay

  




  const handleFixedTextChange = (e) => {
    const newText = e.target.value;
    if (newText.length > 50) {
      setTextError('Max number of characters is 50');
    } else {
      setTextError(null);
      setFixedText(newText);
    }
  };

  const handleNumberChange = (e) => {
    const newNumber = e.target.value;
    if (newNumber < 1 || newNumber > 10) {

    } else {

      setNumberTimestamps(newNumber);
    }
  };

  const handleWordsChange = (e) => {
    const newWords = e.target.value;
    if (newWords.length > 40) {
      setWordsError('Max character limit is 40');
    } else {
      setWordsError(null);
      setCorrectWords(newWords);
    }
  };
  

  return (
    <div className="brand-template-container">
      <div className="input-container">
  <label htmlFor="fixedText">Introduction 📝</label>
  <div className="textarea-container">
    <textarea id="fixedText" value={fixedText} onChange={handleFixedTextChange} rows="4" cols="50" />
    {textError && <div className="error-message">{textError}</div>} {/* Display text error */}
    <div className="char-count">
      {`${fixedText.length}/50`}
    </div>
  </div>
</div>
      <div className="divider"></div>
      <div className="input-container">
        <span className="essential-label">PREVIEW</span>
        <p className="subtitle">Preview 1-10 timestamp in <b>your branded comment</b>.</p>
        <input id="numberTimestamps" type="number" value={numberTimestamps} onChange={handleNumberChange} className="timestamp-input" />
      </div>
      <div className="divider"></div>
      <div className="input-container advanced-feature">
  <label htmlFor="correctWords">Auto-Correct 🪄</label>
  <span className="advanced-label">Advanced</span>
  <p className="subtitle">List words that are often spelled wrong to auto-correct them. (eg: keyword, keyword, keyword...)</p>
  <input id="correctWords" type="text" value={correctWords} onChange={handleWordsChange} />
  {wordsError && <div className="error-message">{wordsError}</div>} {/* Display text error */}
</div>
    <button 
      className={`savetemp-button ${isSaved ? 'savetemp-button-saved' : ''}`} 
      onClick={debouncedSaveToFirestore}
    >
      {isSaved ? 'Branding Saved  🎉' : 'Save Branding  🎨'}
    </button>

      <div className="preview-section">
  <div className="preview-comment">
  <img src={profileImage || placeholderImage} alt="User Profile" className="prevprofile-image" />
    <div className="preview-text">
    {fixedText.split('\n').map((line, i) => <div className="preview-line" key={i}>{line}</div>)}
      {generateSampleTimestamps()}
      {branding && <div>Chat with videos via Bumpups 🌲</div>}
          </div>
        </div>
      </div>
      <div className="input-container essential-feature">
  <div className="checkbox-wrapper">
    <p className="remove-branding-label">
      remove bump<span style={{color: "#16c391"}}>ups</span> branding 
    </p>
    <div className="center-checkbox">  {/* New class applied here */}
      <input 
        id="tubestampBranding" 
        type="checkbox" 
        className="styled-checkbox" 
        onChange={() => setBranding(!branding)}
        checked={!branding} 
        disabled={currentPlan === "Free Plan"}
      />
    </div>
  </div>
  <Link 
  to={currentPlan === "Free Plan" ? "/plans" : "#"} 
  className="nobranding-label"
  style={{ cursor: currentPlan === "Free Plan" ? "pointer" : "default" }}
>
  {currentPlan === "Free Plan" ? "Upgrade Plan To Edit" : (currentPlan === "bump" ? "Bump Plan" : "Base Plan")}
</Link>
</div>
    </div>
    
  );
};

export default BrandTemplate;