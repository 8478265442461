import React, { useState, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './SignUpPage.css'; // Keeping the original name as per your request

// FIREBASE
import {
  signInWithRedirect,
  isSignInWithEmailLink,
  signInWithEmailLink,
  sendSignInLinkToEmail,
  getRedirectResult,
} from 'firebase/auth';
import { auth, googleProvider } from '../firebase';

// FUNCTIONS
import { AuthContext } from '../AuthContext';

// IMPORT UI
import ProcessingPage from './ProcessingPage';

// IMAGES
import googleLogo from '../assets/images/google-official.png'; // Ensure the path is correct
import checkMark from '../assets/images/check-sign.png'; // Updated import

const SignInPage = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(localStorage.getItem('isLoading') === 'true');
  const [emailValidationMsg, setEmailValidationMsg] = useState('');
  const [buttonEmailText, setButtonEmailText] = useState('Send Sign-In Link');

  useEffect(() => {
    if (currentUser) {
      navigate('/dashboard');
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    // Check for redirect result
    getRedirectResult(auth)
      .then((result) => {
        localStorage.setItem('isLoading', 'false');
        setIsLoading(false);
        if (result.user) {
          navigate('/dashboard');
        }
      })
      .catch((error) => {
        localStorage.setItem('isLoading', 'false');
        setIsLoading(false);
        // Handle error if needed
      });
  }, [navigate]);

  useEffect(() => {
    const checkForEmailLinkSignIn = () => {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        let emailForSignIn = window.localStorage.getItem('emailForSignIn');
        if (!emailForSignIn) {
          emailForSignIn = window.prompt('Please provide your email for confirmation');
        }
        signInWithEmailLink(auth, emailForSignIn, window.location.href)
          .then(() => {
            window.localStorage.removeItem('emailForSignIn');
            navigate('/dashboard');
          })
          .catch((error) => {
            // Handle error if needed
          });
      }
    };
    checkForEmailLinkSignIn();
  }, [navigate]);

  const actionCodeSettings = {
    url: window.location.origin + '/signin',
    handleCodeInApp: true,
  };

  const handleEmailSignInLink = async () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      setEmailValidationMsg('Please enter a valid email address.');
      return;
    }
    setEmailValidationMsg('');
    setButtonEmailText('Sending Email...');

    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);
      alert('Check your email for the sign-in link.');
      setButtonEmailText('Send Sign-In Link');
    } catch (error) {
      // Handle error if needed
      setButtonEmailText('Send Sign-In Link');
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      localStorage.setItem('isLoading', 'true');
      await signInWithRedirect(auth, googleProvider);
    } catch (error) {
      localStorage.setItem('isLoading', 'false');
      // Handle error if needed
    }
  };

  return (
    <div className="signup-page">
      <Helmet>
        <title>Bumpups - Sign In</title>
        <meta
          name="description"
          content="Get started with Bumpups and enjoy 60 minutes of free. No credit card required."
        />
      </Helmet>

      {isLoading ? (
        <ProcessingPage />
      ) : (
        <div className="signup-wrapper">
          <div className="signup-content">
            {/* Left Section - Marketing Text */}
            <div className="signup-left">
              <h2>
                Log in to your account
              </h2>
              <ul className="feature-list">
                <li>
                  <img src={checkMark} alt="Checkmark" className="checkmark-icon" />
                  Support for local and YouTube videos
                </li>
                <li>
                  <img src={checkMark} alt="Checkmark" className="checkmark-icon" />
                  Deliver insights across all types of videos
                </li>
                <li>
                  <img src={checkMark} alt="Checkmark" className="checkmark-icon" />
                  Industry-leading Bump AI 
                </li>
              </ul>
            </div>

            {/* Right Section - Sign In Form */}
              <div className="signup-form-container">
                {/* Google Sign-In Button */}
                <button className="google-button" onClick={handleGoogleSignIn}>
                  <div className="google-logo-container">
                    <img
                      src={googleLogo}
                      alt="Google logo"
                      className="google-logo-left"
                    />
                  </div>
                  <span className="google-signup-text">Sign in with Google</span>
                </button>

                {/* Divider */}
                <div className="divider-signpage">
                  <span className="divider-text-signpage">OR</span>
                </div>

                {/* Email Sign-In Section */}
                <div className="input-group">
                  <label className="email-label">
                    <strong>* Email</strong> (required)
                  </label>
                  <input
                    type="email"
                    placeholder=""
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="email-input"
                  />
                  {emailValidationMsg && (
                    <p className="email-validation-msg">{emailValidationMsg}</p>
                  )}
                </div>
                <button className="continue-button" onClick={handleEmailSignInLink}>
                  {buttonEmailText}
                </button>

                {/* Sign Up Prompt */}
                <p className="login-text">
                Don't have a Bumpups account yet? <strong><Link to="/signup" className="highlight-link">Sign up</Link></strong>
              </p>


                {/* Terms Text */}
            
              </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignInPage;
