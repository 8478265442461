import React, { useEffect, useRef, useState, useCallback } from 'react';
import './promptsWorkspace.css';
import examplePrompts from './examplePrompts';
import basePlanLogo from '../../assets/images/baseplan-white.png'; // Import the image
import processBumpupslogo from '../../assets/gifmedia/bumpups-2.gif'; // Corrected path for the loading GIF

// Import Firebase functions and Firestore
import { getFunctions, httpsCallable } from 'firebase/functions';
import { doc, onSnapshot, getDoc } from 'firebase/firestore';
import { db, auth } from '../../firebase';

// Import new icons for copy functionality
import copyIcon from '../../assets/images/copyicon-v1.png';
import checkmarkIcon from '../../assets/images/checkmark-v1.png';

import { generateShortcuts } from './generateShortcuts';

// Import the new ShortCutsTab component
import ShortCutsTab from './shortCutTab';

// Import the new PromptOutput component
import PromptOutput from './promptOutputRender';

const PromptsWorkspace = ({
  isTranscriptAvailable,
  isLocalTranscriptAvailable,
  content_id,
  timestamps,
  summary,
  takeaways,
  titles,
  hashtags,
  isScRun,
  isScTimestampsEnabled, // Shortcut feature flags
  isScDescriptionEnabled,
  isScTakeawaysEnabled,
  isScTitlesEnabled,
  isScTagsEnabled,
}) => {
  
  // Local States
  const [activeTab, setActiveTab] = useState('prompt1'); // Default to 'prompt1'
  const [currentExampleIndex, setCurrentExampleIndex] = useState(0);
  const [shuffledExamples, setShuffledExamples] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false); // State for disabling button

  // States for prompt inputs
  const [prompt1Input, setPrompt1Input] = useState('');
  const [prompt2Input, setPrompt2Input] = useState('');
  const [prompt3Input, setPrompt3Input] = useState('');

  // States for outputs
  const [prompt1Output, setPrompt1Output] = useState('');
  const [prompt2Output, setPrompt2Output] = useState('');
  const [prompt3Output, setPrompt3Output] = useState('');

  // States for loading and debounce
  const [loadingPrompts, setLoadingPrompts] = useState({
    prompt1: false,
    prompt2: false,
    prompt3: false,
  });

  const [debouncedPrompts, setDebouncedPrompts] = useState({
    prompt1: false,
    prompt2: false,
    prompt3: false,
  });

  // State for copy functionality
  const [copiedPrompts, setCopiedPrompts] = useState({
    prompt1: { input: false, output: false },
    prompt2: { input: false, output: false },
    prompt3: { input: false, output: false },
  });
  
  // State for copied details (for tooltips)
  const [copiedDetail, setCopiedDetail] = useState(null);
  const [highlightedTags, setHighlightedTags] = useState([]);

  // Ref for the textarea to handle auto-resizing
  const textareaRef = useRef(null);

  // Initialize Firebase Functions
  const functions = getFunctions();
  const generateWorkspaceOutput = httpsCallable(functions, 'generateWorkspaceOutput');

  // Define a key for local storage using content_id
  const lastActiveTabKey = `activeTab_${content_id}`;

  // State to track data reception for each prompt
  const [dataPromptReceived, setDataPromptReceived] = useState({
    prompt1: false,
    prompt2: false,
    prompt3: false,
  });

  // Shuffle the examples on mount
  useEffect(() => {
    if (Array.isArray(examplePrompts)) {
      const shuffled = [...examplePrompts].sort(() => Math.random() - 0.5);
      setShuffledExamples(shuffled);
    }
  }, []);

  // Cycle through examples every 4 seconds
  useEffect(() => {
    if (shuffledExamples.length === 0) return; // Prevent division by zero
    const interval = setInterval(() => {
      setCurrentExampleIndex(
        (prevIndex) => (prevIndex + 1) % shuffledExamples.length
      );
    }, 4000); // Change example every 4 seconds
    return () => clearInterval(interval);
  }, [shuffledExamples.length, shuffledExamples]);

  // Disable button if '/new' is in the URL
  useEffect(() => {
    const isNew = window.location.pathname.includes('/new');
    setIsDisabled(isNew);
  }, []);

  // Helper functions to check the existence of each data type
  const hasTimestamps = () => typeof timestamps === 'string' && timestamps.trim() !== '';
  const hasDescription = () => summary && summary.trim() !== '';
  const hasTakeaways = () => Array.isArray(takeaways) && takeaways.length > 0;
  const hasTitles = () => Array.isArray(titles) && titles.length > 0;
  const hasHashtags = () => Array.isArray(hashtags) && hashtags.length > 0;

  // Function to check if any additional data exists or is enabled
  const hasDetailsData = () => {
    return (
      isScTimestampsEnabled ||
      isScDescriptionEnabled ||
      isScTakeawaysEnabled ||
      isScTitlesEnabled ||
      isScTagsEnabled
    );
  };

  // Utility functions for debounce
  const getDebounceKey = (uid, contentId, promptId) => `debounce_${uid}_${contentId}_prompt${promptId}`;

  const isDebounced = (uid, contentId, promptId) => {
    const key = getDebounceKey(uid, contentId, promptId);
    const expire = localStorage.getItem(key);
    if (!expire) return false;
    if (Date.now() > parseInt(expire, 10)) {
      localStorage.removeItem(key);
      return false;
    }
    return true;
  };

  const setDebounce = (uid, contentId, promptId) => {
    const key = getDebounceKey(uid, contentId, promptId);
    const expire = Date.now() + 600000; // 10 Minutes
    localStorage.setItem(key, expire.toString());
  };

  // Initialize debouncedPrompts and set default activeTab on mount
  useEffect(() => {
    const initializeDebounces = () => {
      const user = auth.currentUser;
      if (!user) return;

      ['prompt1', 'prompt2', 'prompt3'].forEach((promptKey, index) => {
        const promptId = index + 1;
        const key = getDebounceKey(user.uid, content_id, promptId);
        const expire = localStorage.getItem(key);
        if (expire && Date.now() < parseInt(expire, 10)) {
          setDebouncedPrompts(prev => ({ ...prev, [promptKey]: true }));
          setLoadingPrompts(prev => ({ ...prev, [promptKey]: true }));

          const timeout = parseInt(expire, 10) - Date.now();
          setTimeout(() => {
            setDebouncedPrompts(prev => ({ ...prev, [promptKey]: false }));
            setLoadingPrompts(prev => ({ ...prev, [promptKey]: false }));
            localStorage.removeItem(key);
          }, timeout);
        }
      });
    };

    initializeDebounces();

    const storedActiveTab = localStorage.getItem(lastActiveTabKey);
    if (storedActiveTab) {
      setActiveTab(storedActiveTab);
    } else {
      setActiveTab('prompt1');
    }

  }, [content_id, lastActiveTabKey]);

  // Load prompt inputs either from Firestore or localStorage
  useEffect(() => {
    const user = auth.currentUser;
    if (!user) return;

    const workspaceDocRef = doc(db, 'users', user.uid, 'workspaceContent', `workspace-${content_id}`);

    const loadPrompts = async () => {
      try {
        const docSnap = await getDoc(workspaceDocRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          // Check and set prompt inputs from Firestore
          if (data.prompt1_input) {
            setPrompt1Input(data.prompt1_input);
          } else {
            // Fallback to localStorage
            const savedInput = localStorage.getItem(`prompt1_input_${content_id}`);
            if (savedInput) setPrompt1Input(savedInput);
          }

          if (data.prompt2_input) {
            setPrompt2Input(data.prompt2_input);
          } else {
            const savedInput = localStorage.getItem(`prompt2_input_${content_id}`);
            if (savedInput) setPrompt2Input(savedInput);
          }

          if (data.prompt3_input) {
            setPrompt3Input(data.prompt3_input);
          } else {
            const savedInput = localStorage.getItem(`prompt3_input_${content_id}`);
            if (savedInput) setPrompt3Input(savedInput);
          }
        } else {
          // If document doesn't exist, load from localStorage
          const savedInput1 = localStorage.getItem(`prompt1_input_${content_id}`);
          const savedInput2 = localStorage.getItem(`prompt2_input_${content_id}`);
          const savedInput3 = localStorage.getItem(`prompt3_input_${content_id}`);

          if (savedInput1) setPrompt1Input(savedInput1);
          if (savedInput2) setPrompt2Input(savedInput2);
          if (savedInput3) setPrompt3Input(savedInput3);
        }
      } catch (error) {
        // console.error('Error loading prompts:', error);
      }
    };

    loadPrompts();

    // Set up onSnapshot listener for Firestore updates
    const unsubscribe = onSnapshot(workspaceDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();

        // Update Prompt 1 Input
        if (data.prompt1_input && data.prompt1_input !== prompt1Input) {
          setPrompt1Input(data.prompt1_input);
        }

        // Update Prompt 2 Input
        if (data.prompt2_input && data.prompt2_input !== prompt2Input) {
          setPrompt2Input(data.prompt2_input);
        }

        // Update Prompt 3 Input
        if (data.prompt3_input && data.prompt3_input !== prompt3Input) {
          setPrompt3Input(data.prompt3_input);
        }

        // Also update outputs if needed
        if (data.prompt1_output && data.prompt1_output !== prompt1Output) {
          setPrompt1Output(data.prompt1_output);
          setDataPromptReceived(prev => ({ ...prev, prompt1: true }));
        }

        if (data.prompt2_output && data.prompt2_output !== prompt2Output) {
          setPrompt2Output(data.prompt2_output);
          setDataPromptReceived(prev => ({ ...prev, prompt2: true }));
        }

        if (data.prompt3_output && data.prompt3_output !== prompt3Output) {
          setPrompt3Output(data.prompt3_output);
          setDataPromptReceived(prev => ({ ...prev, prompt3: true }));
        }

      }
    }, (error) => {
      // console.error('Error in onSnapshot:', error);
    });

    return () => unsubscribe();
  }, [content_id, prompt1Input, prompt2Input, prompt3Input, prompt1Output, prompt2Output, prompt3Output]);

  // const prevPromptOutputs = useRef({ prompt1: '', prompt2: '', prompt3: '' });

  // New States for Reload Button
  const [isReloadDisabled, setIsReloadDisabled] = useState(false);
  const [reloadCooldown, setReloadCooldown] = useState(0); // To show cooldown timer

  // useEffect for cooldown timer
  useEffect(() => {
    const savedCooldownEnd = localStorage.getItem(`reloadCooldownEnd_${content_id}`);
    
    if (savedCooldownEnd) {
        const timeRemaining = Math.max(0, Math.floor((savedCooldownEnd - Date.now()) / 1000));
        if (timeRemaining > 0) {
            setIsReloadDisabled(true);
            setReloadCooldown(timeRemaining);
        }
    }

    let timer;
    if (reloadCooldown > 0) {
        timer = setInterval(() => {
            setReloadCooldown((prev) => prev - 1);
        }, 1000);
    } else if (reloadCooldown === 0 && isReloadDisabled) {
        setIsReloadDisabled(false);
        localStorage.removeItem(`reloadCooldownEnd_${content_id}`);
    }

    return () => clearInterval(timer);
}, [reloadCooldown, isReloadDisabled, content_id]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    localStorage.setItem(lastActiveTabKey, tab);
  };

  const handleInputChange = (e, promptSetter, promptId) => {
    const value = e.target.value;
    if (value.length <= 250) {
      promptSetter(value);
      autoResizeTextarea(e.target);

      // Save to localStorage
      localStorage.setItem(`prompt${promptId}_input_${content_id}`, value);
    }
  };

  // Handle copy functionality
  const handleCopy = (promptKey, text, type) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedPrompts((prev) => ({
        ...prev,
        [promptKey]: { ...prev[promptKey], [type]: true },
      }));
      setTimeout(() => {
        setCopiedPrompts((prev) => ({
          ...prev,
          [promptKey]: { ...prev[promptKey], [type]: false },
        }));
      }, 3000); // 3 seconds
    });
  };

  // Handle Reload button click
  const handleReload = async () => {
    if (isReloadDisabled) return;

    setIsReloadDisabled(true);
    const cooldownDuration = 120; // 120 seconds
    const cooldownEndTime = Date.now() + cooldownDuration * 1000;
    localStorage.setItem(`reloadCooldownEnd_${content_id}`, cooldownEndTime);
    setReloadCooldown(cooldownDuration);

    const shortcutOptions = {
      timestamps: isScTimestampsEnabled,
      description: isScDescriptionEnabled,
      takeaways: isScTakeawaysEnabled,
      titles: isScTitlesEnabled,
      tags: isScTagsEnabled,
      isReprocess: true,
    };

    try {
      await generateShortcuts(content_id, shortcutOptions);
    } catch (error) {
      // console.error('Error generating shortcuts:', error);
      // Optionally, reset cooldown if there's an error
      setIsReloadDisabled(false);
      setReloadCooldown(0);
      localStorage.removeItem(`reloadCooldownEnd_${content_id}`);
    }
  };

  const handleWorkspaceOutput = async (promptInput, promptId) => {
    try {
      const user = auth.currentUser;
      if (!user) return;

      const promptKey = `prompt${promptId}`;

      // Reset data reception state
      setDataPromptReceived(prev => ({ ...prev, [promptKey]: false }));

      if (isDebounced(user.uid, content_id, promptId)) return;

      setDebounce(user.uid, content_id, promptId);
      setDebouncedPrompts(prev => ({ ...prev, [promptKey]: true }));
      setLoadingPrompts(prev => ({ ...prev, [promptKey]: true }));

      setTimeout(() => {
        setDebouncedPrompts(prev => ({ ...prev, [promptKey]: false }));
        setLoadingPrompts(prev => ({ ...prev, [promptKey]: false }));
        setDataPromptReceived(prev => ({ ...prev, [promptKey]: false }));
        localStorage.removeItem(getDebounceKey(user.uid, content_id, promptId));
      }, 600000); // 10 minutess

      const response = await generateWorkspaceOutput({
        contentId: `workspace-${content_id}`,
        fullPrompt: promptInput,
        promptId: promptId,
      });

      // Handle response as needed
      // For example, set prompt output
      if (response.data && response.data.result) {
        if (promptId === 1) setPrompt1Output(response.data.result);
        if (promptId === 2) setPrompt2Output(response.data.result);
        if (promptId === 3) setPrompt3Output(response.data.result);
      }

    } catch (error) {
      // console.error('Error generating workspace output:', error);
      const promptKey = `prompt${promptId}`;
      setLoadingPrompts(prev => ({ ...prev, [promptKey]: false }));
    }
  };

  const autoResizeTextarea = (textarea) => {
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const renderPromptBox = (promptKey, promptInput, setPromptInput, promptId) => {
    const currentExample = shuffledExamples[currentExampleIndex];
    
    let promptOutput = '';
    if (promptKey === 'prompt1') {
      promptOutput = prompt1Output;
    } else if (promptKey === 'prompt2') {
      promptOutput = prompt2Output;
    } else if (promptKey === 'prompt3') {
      promptOutput = prompt3Output;
    }

    const hasInput = !!promptInput;
    const hasOutput = !!promptOutput;
    const isLoading = loadingPrompts[promptKey] && !hasOutput;
    const isDebouncedPrompt = debouncedPrompts[promptKey];
    const isCopiedInput = copiedPrompts[promptKey]?.input;
    const isCopiedOutput = copiedPrompts[promptKey]?.output;
    const dataReceived = dataPromptReceived[promptKey];

    return (
      <div className="workprompt-promptBox">
        {hasInput && hasOutput ? (
          <div className="workprompt-readOnlyContainer">
            <div className="workprompt-readOnlyInput">
              <div className="workprompt-readOnlyContent">
                <div className="workprompt-readOnlyText">{promptInput}</div>
                <button
                  className="workprompt-copyButton"
                  onClick={() => handleCopy(promptKey, promptInput, 'input')}
                  aria-label="Copy Prompt"
                >
                  {isCopiedInput ? (
                    <img src={checkmarkIcon} alt="Copied" className="workprompt-checkmarkIcon" />
                  ) : (
                    <img src={copyIcon} alt="Copy" className="workprompt-copyIcon" />
                  )}
                </button>
              </div>
            </div>
            
            <PromptOutput
              promptKey={promptKey}
              promptOutput={promptOutput}
              isCopiedOutput={isCopiedOutput}
              handleCopy={handleCopy}
            />
          </div>
        ) : (
          <div className="workprompt-promptBoxInner">
            <div className="workprompt-input-container">
              <textarea
                className="workprompt-textarea"
                value={promptInput}
                onChange={(e) => handleInputChange(e, setPromptInput, promptId)}
                placeholder="Message Bump AI"
                maxLength="250"
                ref={textareaRef}
                rows={1}
              />
              <button
                className="workprompt-submitButton"
                onClick={() => handleWorkspaceOutput(promptInput, promptId)}
                disabled={
                  !promptInput.trim() ||
                  isDisabled ||
                  (!isTranscriptAvailable && !isLocalTranscriptAvailable) ||
                  isDebouncedPrompt ||
                  isLoading
                }
                aria-label="Submit Prompt"
              >
                <img src={basePlanLogo} alt="Submit" className="submit-button-icon" />
              </button>
            </div>
            <div className="workprompt-charCount">
              {promptInput.length}/250
            </div>
            {!promptOutput && currentExample && (
              <div className="workprompt-examples">
                <div className="workprompt-example-text">
                  <span className="workprompt-example-badge">
                    {currentExample.category}
                  </span>
                  "{currentExample.text}"
                </div>
              </div>
            )}
            {promptOutput && (
              <PromptOutput
                promptKey={promptKey}
                promptOutput={promptOutput}
                isCopiedOutput={isCopiedOutput}
                handleCopy={handleCopy}
              />
            )}

            {isLoading && !dataReceived && (
              <div className="workprompt-loadingOverlay">
                <img src={processBumpupslogo} alt="Loading" className="workprompt-loadingGif" />
                <div className="workprompt-loadingText">
                  Analyzing<span className="workprompt-loadingDots">...</span>
                </div>
              </div>
            )}

          </div>
        )}
      </div>
    );
  };

  const handleCopyDetails = (text, detailType) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedDetail(detailType);
      setTimeout(() => setCopiedDetail(null), 3000);
    });
  };

  useEffect(() => {
    if (Array.isArray(hashtags)) {
      const highlightLength = Math.min(5, hashtags.length);
      const highlightIndices = Array.from({ length: highlightLength }, (_, i) => i);
      setHighlightedTags(highlightIndices);
    } else {
      setHighlightedTags([]);
    }
  }, [hashtags]);

  return (
    <div className="workprompt-container">
      <div className="workprompt-title">
        Prompts Workspace
        <span className="workprompt-beta-badge">beta</span>
      </div>
      <p className="workprompt-intro">
        Upload any video and interact directly with its content. Ask questions,
        request summaries, analyses, and more.
      </p>
      <div className="workprompt-tabs">
        {['Prompt 1', 'Prompt 2', 'Prompt 3'].map((prompt, index) => {
          const promptKey = `prompt${index + 1}`;
          return (
            <button
              key={promptKey}
              className={`workprompt-tabButton ${
                activeTab === promptKey ? 'active' : ''
              }`}
              onClick={() => handleTabClick(promptKey)}
            >
              {prompt}
            </button>
          );
        })}
        {hasDetailsData() && isScRun && (
          <button
            key="details"
            className={`workprompt-tabButton ${
              activeTab === 'details' ? 'active' : ''
            }`}
            onClick={() => handleTabClick('details')}
          >
            <b>Shortcuts</b>
          </button>
        )}
      </div>
      <div className="workprompt-content">
        {activeTab === 'prompt1' && renderPromptBox('prompt1', prompt1Input, setPrompt1Input, 1)}
        {activeTab === 'prompt2' && renderPromptBox('prompt2', prompt2Input, setPrompt2Input, 2)}
        {activeTab === 'prompt3' && renderPromptBox('prompt3', prompt3Input, setPrompt3Input, 3)}
        {activeTab === 'details' && (
          <ShortCutsTab
            isScTimestampsEnabled={isScTimestampsEnabled}
            isScDescriptionEnabled={isScDescriptionEnabled}
            isScTakeawaysEnabled={isScTakeawaysEnabled}
            isScTitlesEnabled={isScTitlesEnabled}
            isScTagsEnabled={isScTagsEnabled}
            timestamps={timestamps}
            summary={summary}
            takeaways={takeaways}
            titles={titles}
            hashtags={hashtags}
            isScRun={isScRun}
            needsReload={
              (isScTimestampsEnabled && !hasTimestamps()) ||
              (isScDescriptionEnabled && !hasDescription()) ||
              (isScTakeawaysEnabled && !hasTakeaways()) ||
              (isScTitlesEnabled && !hasTitles()) ||
              (isScTagsEnabled && !hasHashtags())
            }
            isReloadDisabled={isReloadDisabled}
            reloadCooldown={reloadCooldown}
            handleReload={handleReload}
            handleCopyDetails={handleCopyDetails}
            copiedDetail={copiedDetail}
            highlightedTags={highlightedTags}
          />
        )}
        <div className="workprompt-disclaimer">
          Bump AI can make mistakes. Check important info.
        </div>
      </div>
    </div>
  );
};

export default PromptsWorkspace;
