import React from 'react';
import './PricingTable.css';
import checkmarkIcon from '../assets/images/greencheck-v1.png';
import utubeLogo from '../assets/images/v2-creator.png'; // Import the image

const PricingTable = () => {
  return (
    <div className="btable-container">
      <h2 className="btable-title">Choose the plan that’s right for you
      </h2>
      <table className="btable-table">
        <thead>
          <tr>
            <th className="btable-header"></th>
            <th className="btable-header">Bump</th>
            <th className="btable-header">Base</th>
            <th className="btable-header">Free</th>
          </tr>
        </thead>
        <tbody>
          {/* FUNDAMENTAL Section */}
          <tr>
            <td className="btable-category" colSpan="4">FUNDAMENTAL</td>
          </tr>
          <tr className="btable-feature-row">
            <td className="btable-feature">Industry leading processing speed</td>
            <td className="btable-center-text"><img src={checkmarkIcon} alt="Checkmark" className="btable-checkmark-icon" /></td>
            <td className="btable-center-text"><img src={checkmarkIcon} alt="Checkmark" className="btable-checkmark-icon" /></td>
            <td className="btable-center-text"><img src={checkmarkIcon} alt="Checkmark" className="btable-checkmark-icon" /></td>
          </tr>
          <tr className="btable-feature-row">
            <td className="btable-feature">Video min length</td>
            <td className="btable-center-text">30s</td>
            <td className="btable-center-text">4m</td>
            <td className="btable-center-text">4m</td>
          </tr>
          <tr className="btable-feature-row">
            <td className="btable-feature">Video max length</td>
            <td className="btable-center-text">3h 30m</td>
            <td className="btable-center-text">3h 30m</td>
            <td className="btable-center-text">3h 30m</td>
          </tr>
          <tr className="btable-feature-row">
            <td className="btable-feature">Video quality</td>
            <td className="btable-center-text">SD, HD, 4K</td>
            <td className="btable-center-text">SD, HD, 4K</td>
            <td className="btable-center-text">SD, HD, 4K</td>
          </tr>
          <tr className="btable-feature-row">
            <td className="btable-feature">Import sources</td>
            <td className="btable-center-text"><img src={utubeLogo} alt="YouTube Logo" className="btable-socialmedia-icon" /> and local videos</td>
            <td className="btable-center-text"><img src={utubeLogo} alt="YouTube Logo" className="btable-socialmedia-icon" /></td>
            <td className="btable-center-text">
              <img src={utubeLogo} alt="YouTube Logo" className="btable-socialmedia-icon" />
            </td>
          </tr>
          <tr className="btable-feature-row">
            <td className="btable-feature">Upload local file</td>
            <td className="btable-center-text"><img src={checkmarkIcon} alt="Checkmark" className="btable-checkmark-icon" /></td>
            <td className="btable-center-text">-</td>
            <td className="btable-center-text">-</td>
          </tr>
          <tr className="btable-feature-row">
            <td className="btable-feature">Max local file size</td>
            <td className="btable-center-text">32GB</td>
            <td className="btable-center-text">-</td>
            <td className="btable-center-text">-</td>
          </tr>
          <tr className="btable-feature-row">
            <td className="btable-feature">Multiple aspect ratios</td>
            <td className="btable-center-text">16:9, 9:16</td>
            <td className="btable-center-text">16:9</td>
            <td className="btable-center-text">16:9</td>
          </tr>
          {/* Bump AI Section */}
          <tr>
            <td className="btable-category" colSpan="4">Bump AI</td>
          </tr>
          <tr className="btable-feature-row">
            <td className="btable-feature">Prompts Workspace</td>
            <td className="btable-center-text"><img src={checkmarkIcon} alt="Checkmark" className="btable-checkmark-icon" /></td>
            <td className="btable-center-text"><img src={checkmarkIcon} alt="Checkmark" className="btable-checkmark-icon" /></td>
            <td className="btable-center-text"><img src={checkmarkIcon} alt="Checkmark" className="btable-checkmark-icon" /></td>
          </tr>
          <tr className="btable-feature-row">
            <td className="btable-feature">Video timestamps</td>
            <td className="btable-center-text"><img src={checkmarkIcon} alt="Checkmark" className="btable-checkmark-icon" /></td>
            <td className="btable-center-text"><img src={checkmarkIcon} alt="Checkmark" className="btable-checkmark-icon" /></td>
            <td className="btable-center-text"><img src={checkmarkIcon} alt="Checkmark" className="btable-checkmark-icon" /></td>
          </tr>
          <tr className="btable-feature-row">
            <td className="btable-feature">Video titles</td>
            <td className="btable-center-text"><img src={checkmarkIcon} alt="Checkmark" className="btable-checkmark-icon" /></td>
            <td className="btable-center-text"><img src={checkmarkIcon} alt="Checkmark" className="btable-checkmark-icon" /></td>
            <td className="btable-center-text"><img src={checkmarkIcon} alt="Checkmark" className="btable-checkmark-icon" /></td>
          </tr>
          <tr className="btable-feature-row">
            <td className="btable-feature">Video descriptions</td>
            <td className="btable-center-text"><img src={checkmarkIcon} alt="Checkmark" className="btable-checkmark-icon" /></td>
            <td className="btable-center-text"><img src={checkmarkIcon} alt="Checkmark" className="btable-checkmark-icon" /></td>
            <td className="btable-center-text"><img src={checkmarkIcon} alt="Checkmark" className="btable-checkmark-icon" /></td>
          </tr>
          <tr className="btable-feature-row">
            <td className="btable-feature">Key takeaways</td>
            <td className="btable-center-text"><img src={checkmarkIcon} alt="Checkmark" className="btable-checkmark-icon" /></td>
            <td className="btable-center-text"><img src={checkmarkIcon} alt="Checkmark" className="btable-checkmark-icon" /></td>
            <td className="btable-center-text"><img src={checkmarkIcon} alt="Checkmark" className="btable-checkmark-icon" /></td>
          </tr>
          <tr className="btable-feature-row">
            <td className="btable-feature">Video hashtags</td>
            <td className="btable-center-text"><img src={checkmarkIcon} alt="Checkmark" className="btable-checkmark-icon" /></td>
            <td className="btable-center-text"><img src={checkmarkIcon} alt="Checkmark" className="btable-checkmark-icon" /></td>
            <td className="btable-center-text"><img src={checkmarkIcon} alt="Checkmark" className="btable-checkmark-icon" /></td>
          </tr>
          <tr>
            <td className="btable-category" colSpan="4">Distribution</td>
          </tr>
          <tr className="btable-feature-row">
            <td className="btable-feature">One-click distribution</td>
            <td className="btable-center-text"><img src={checkmarkIcon} alt="Checkmark" className="btable-checkmark-icon" /></td>
            <td className="btable-center-text">-</td>
            <td className="btable-center-text">-</td>
          </tr>
          <tr className="btable-feature-row">
            <td className="btable-feature">Social media posting</td>
            <td className="btable-center-text">
            <img src={utubeLogo} alt="YouTube Logo" className="btable-socialmedia-icon" />  
             </td>
            <td className="btable-center-text">-</td>
            <td className="btable-center-text">-</td>
          </tr>
          <tr className="btable-feature-row">
            <td className="btable-feature">Social media scheduler</td>
            <td className="btable-center-text">
            <img src={utubeLogo} alt="YouTube Logo" className="btable-socialmedia-icon" />  
             </td>
            <td className="btable-center-text">-</td>
            <td className="btable-center-text">-</td>
          </tr>

          <tr>
            <td className="btable-category" colSpan="4">Customization & Branding</td>
          </tr>
          <tr className="btable-feature-row">
            <td className="btable-feature">Video analytics</td>
            <td className="btable-center-text"><img src={checkmarkIcon} alt="Checkmark" className="btable-checkmark-icon" /></td>
            <td className="btable-center-text"><img src={checkmarkIcon} alt="Checkmark" className="btable-checkmark-icon" /></td>
            <td className="btable-center-text"><img src={checkmarkIcon} alt="Checkmark" className="btable-checkmark-icon" /></td>
          </tr>
          <tr className="btable-feature-row">
            <td className="btable-feature">Custom brand template</td>
            <td className="btable-center-text"><img src={checkmarkIcon} alt="Checkmark" className="btable-checkmark-icon" /></td>
            <td className="btable-center-text"><img src={checkmarkIcon} alt="Checkmark" className="btable-checkmark-icon" /></td>
            <td className="btable-center-text">-</td>
          </tr>
          <tr className="btable-feature-row">
            <td className="btable-feature">Branded comments</td>
            <td className="btable-center-text"><img src={checkmarkIcon} alt="Checkmark" className="btable-checkmark-icon" /></td>
            <td className="btable-center-text"><img src={checkmarkIcon} alt="Checkmark" className="btable-checkmark-icon" /></td>
            <td className="btable-center-text">-</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PricingTable;
