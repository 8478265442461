import React, { useEffect, useState } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db, auth } from '../../../../firebase';
import LinksNotification from '../../../../components/linksNotification';
import YTVideoProcessor from './YTVideoProcessor';
import YTVideoDisplay from './YTVideoDisplay';
import './linkWorkspace.css';

const LinkWorkspace = ({ onInputChange, content_id, isTranscriptAvailable,  isYTProcessing }) => {
    const [workspaceVideoDetails, setWorkspaceVideoDetails] = useState({});
    const [doneVideos, setDoneVideos] = useState([]);

    // Firestore subscription to monitor workspace content updates
    useEffect(() => {
        const user = auth.currentUser;
        if (user && content_id) {
            const workspaceDocRef = doc(db, 'users', user.uid, 'workspaceContent', `workspace-${content_id}`);
        
            const unsubscribe = onSnapshot(workspaceDocRef, (docSnap) => {
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    const { yt_video_id, yt_video_title, video_thumbnail, video_duration_in_seconds } = data;


                    const localStorageKey = `workspacelink_${user.uid}_${content_id}`;
                    
                    if (isTranscriptAvailable) {
                        const storedData = localStorage.getItem(localStorageKey);
                        if (storedData) {
                            const parsedData = JSON.parse(storedData);
                            
                            if (workspaceVideoDetails.videoId !== parsedData.videoId) {
                                setWorkspaceVideoDetails(parsedData);
                                setDoneVideos(prevDone => [...prevDone, parsedData.videoId]);
                                onInputChange(true);
                            }
                        } else {
                            if (yt_video_id && yt_video_title && video_thumbnail && video_duration_in_seconds) {
                                const workspaceDetails = {
                                    thumbnailUrl: video_thumbnail,
                                    durationSeconds: video_duration_in_seconds,
                                    captionAvailable: true,
                                    videoId: yt_video_id,
                                    title: yt_video_title,
                                };
                                setWorkspaceVideoDetails(workspaceDetails);
                                localStorage.setItem(localStorageKey, JSON.stringify(workspaceDetails));
                                setDoneVideos(prevDone => [...prevDone, yt_video_id]);
                                onInputChange(true);
                            }
                        }
                    } else {
                        if (yt_video_id && yt_video_title && video_thumbnail && video_duration_in_seconds) {
                            const workspaceDetails = {
                                thumbnailUrl: video_thumbnail,
                                durationSeconds: video_duration_in_seconds,
                                captionAvailable: false,
                                videoId: yt_video_id,
                                title: yt_video_title,
                            };
                            setWorkspaceVideoDetails(workspaceDetails);
                            setDoneVideos(prevDone => [...prevDone, yt_video_id]);
                            onInputChange(true);
                        }
                    }
                }
            }, (error) => {
                // console.error("Error fetching workspace content:", error);
            });
        
            return () => unsubscribe();
        }
    }, [content_id, isTranscriptAvailable, onInputChange, workspaceVideoDetails.videoId]);
    

    return (
        <div className="worklink-uploaddashboard">
            <div className="worklink-dashboard-container">
                <LinksNotification />
                {!isTranscriptAvailable ? (
                    <YTVideoProcessor
                        workspaceVideoDetails={workspaceVideoDetails}
                        setWorkspaceVideoDetails={setWorkspaceVideoDetails}
                        setDoneVideos={setDoneVideos}
                        doneVideos={doneVideos}
                        onInputChange={onInputChange}
                        content_id={content_id}
                        isTranscriptAvailable={isTranscriptAvailable}
                        isYTProcessing={isYTProcessing} // Passing isYTProcessing to YTVideoProcessor

                    />
                ) : (
                    <YTVideoDisplay
                        workspaceVideoDetails={workspaceVideoDetails}
                        doneVideos={doneVideos}
                    />
                )}
                {/* Additional sections like Video Details can be added here if needed */}
            </div>
        </div>
    );
};

export default LinkWorkspace;
