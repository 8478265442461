import React, { useState, useEffect } from 'react';
import './HomePage.css';

//IMAGES
import processBumpupslogo from '../assets/gifmedia/bumpups-2.gif';

const loadingMessages = [
  "Chatting with your videos...",
  "Binge-Watching Your Videos...",
  "Hunting for Easter Eggs in Videos...",
  "Getting Lost in a YouTube Rabbit Hole...",
  "Asking the Algorithm for Directions...",
];


const getRandomMessage = () => {
  const randomIndex = Math.floor(Math.random() * loadingMessages.length);
  return loadingMessages[randomIndex];
};

function ProcessingPage() {
  const [currentMessage, setCurrentMessage] = useState(getRandomMessage());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentMessage(getRandomMessage());
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="processing-container">
      <img src={processBumpupslogo} alt="----" className="processing-image-logo" />
      <h2 className="processing-text">{currentMessage}</h2>
      <p className="process-fixed-text">[don't leave window ⏳]</p>
    </div>
  );
}

export default ProcessingPage;
