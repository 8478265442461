import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { doc, onSnapshot } from 'firebase/firestore';
import { db, auth } from '../../firebase';
import './templateWorkspace.css'; // Shared CSS
import Sidebar from '../../myDashboard/Sidebar';
import CloseSidebar from '../../myDashboard/CloseSidebar';
import AppTransition from '../../components/AppTransition';

import WorkspaceHeader from './workspaceUploads/WorkspaceHeader';
import UploadWorkspace from './workspaceUploads/uploadWorkspace';
import PromptsWorkspace from './promptsWorkspace';
import ShortcutsWorkspace from './shortcutsWorkspace';
import ProcessingPage from '../../unAuthPages/ProcessingPage';

import { updateWorkspace } from './updateWorkspace';

const ExistingWorkspace = ({ isSidebarOpen, toggleSidebar }) => {
  const user = auth.currentUser;
  const { content_id: rawContentId } = useParams();

  const content_id = rawContentId?.startsWith('workspace-')
    ? rawContentId.replace(/^workspace-/, '')
    : rawContentId;

  // State variables for current workspace data
  const [workspaceTitle, setWorkspaceTitle] = useState('');
  const [isScTimestampsEnabled, setIsScTimestampsEnabled] = useState(false);
  const [isScDescriptionEnabled, setIsScDescriptionEnabled] = useState(false);
  const [isScTakeawaysEnabled, setIsScTakeawaysEnabled] = useState(false);
  const [isScTitlesEnabled, setIsScTitlesEnabled] = useState(false);
  const [isScTagsEnabled, setIsScTagsEnabled] = useState(false);
  const [isTranscriptAvailable, setIsTranscriptAvailable] = useState(false);
  const [isLocalTranscriptAvailable, setIsLocalTranscriptAvailable] = useState(false); // New state
  const [isYTProcessing, setIsYTProcessing] = useState(false); // Existing state for is_caption_processing
  const [isLOCALProcessing, setIsLOCALProcessing] = useState(false); // New state for is_local_caption_processing
  const [isTranscriptError, setIsTranscriptError] = useState(false); // New state for is_transcript_error

  // New state variables for additional data
  const [timestamps, setTimestamps] = useState('');
  const [summary, setSummary] = useState('');
  const [takeaways, setTakeaways] = useState([]);
  const [titles, setTitles] = useState([]);
  const [hashtags, setHashtags] = useState('');
  const [isScRun, setisScRun] = useState(false); // has the generate shortcuts already run?

  // State variables for original workspace data (for comparison)
  const [originalWorkspaceTitle, setOriginalWorkspaceTitle] = useState('');
  const [originalIsScTimestampsEnabled, setOriginalIsScTimestampsEnabled] = useState(false);
  const [originalIsScDescriptionEnabled, setOriginalIsScDescriptionEnabled] = useState(false);
  const [originalIsScTakeawaysEnabled, setOriginalIsScTakeawaysEnabled] = useState(false);
  const [originalIsScTitlesEnabled, setOriginalIsScTitlesEnabled] = useState(false);
  const [originalIsScTagsEnabled, setOriginalIsScTagsEnabled] = useState(false);
  const [originalIsTranscriptAvailable, setOriginalIsTranscriptAvailable] = useState(false);
  const [originalIsLocalTranscriptAvailable, setOriginalIsLocalTranscriptAvailable] = useState(false); // New original state
  const [originalIsYTProcessing, setOriginalIsYTProcessing] = useState(false); // Original state for isYTProcessing
  const [originalIsLOCALProcessing, setOriginalIsLOCALProcessing] = useState(false); // New original state
  const [originalIsTranscriptError, setOriginalIsTranscriptError] = useState(false); // New original state

  const [loading, setLoading] = useState(true);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false); // State for enabling save button

  useEffect(() => {
    if (content_id && user) {
      const userDocRef = doc(db, 'users', user.uid);
      const workspaceDocRef = doc(userDocRef, 'workspaceContent', `workspace-${content_id}`);

      const localDataKey = `workspace-${content_id}-data`;
      const localDataJSON = localStorage.getItem(localDataKey);

      if (localDataJSON) {
        const workspaceData = JSON.parse(localDataJSON);

        // Set initial state from local storage if available
        setWorkspaceTitle(workspaceData.workspace_title || '');
        setIsScTimestampsEnabled(workspaceData.is_sc_timestamps_enabled || false);
        setIsScDescriptionEnabled(workspaceData.is_sc_description_enabled || false);
        setIsScTakeawaysEnabled(workspaceData.is_sc_takeaways_enabled || false);
        setIsScTitlesEnabled(workspaceData.is_sc_titles_enabled || false);
        setIsScTagsEnabled(workspaceData.is_sc_tags_enabled || false);
        setIsTranscriptAvailable(workspaceData.is_transcript_available || false);
        setIsLocalTranscriptAvailable(workspaceData.is_local_transcript_available || false); // Initialize new state
        setIsYTProcessing(workspaceData.is_caption_processing || false); // Initialize with local data
        setIsLOCALProcessing(workspaceData.is_local_caption_processing || false); // Initialize new state
        setIsTranscriptError(workspaceData.is_transcript_error || false); // Initialize new state

        setTimestamps(workspaceData.timestamps || '');
        setSummary(workspaceData.summary || '');
        setTakeaways(workspaceData.takeaways || []);
        setTitles(workspaceData.titles || []);
        setHashtags(workspaceData.hashtags || '');
        setisScRun(workspaceData.is_sc_run || false);

        setLoading(false);
      } else {
        setLoading(true);
      }

      // Set up onSnapshot listener
      const unsubscribeMeta = onSnapshot(
        workspaceDocRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            const workspaceData = docSnapshot.data();

            // Update state with the latest Firestore data
            setWorkspaceTitle(workspaceData.workspace_title || '');
            setIsScTimestampsEnabled(workspaceData.is_sc_timestamps_enabled || false);
            setIsScDescriptionEnabled(workspaceData.is_sc_description_enabled || false);
            setIsScTakeawaysEnabled(workspaceData.is_sc_takeaways_enabled || false);
            setIsScTitlesEnabled(workspaceData.is_sc_titles_enabled || false);
            setIsScTagsEnabled(workspaceData.is_sc_tags_enabled || false);
            setIsTranscriptAvailable(workspaceData.is_transcript_available || false);
            setIsLocalTranscriptAvailable(workspaceData.is_local_transcript_available || false); // Update new state
            setIsYTProcessing(workspaceData.is_caption_processing || false); // Existing state
            setIsLOCALProcessing(workspaceData.is_local_caption_processing || false); // Update new state
            setIsTranscriptError(workspaceData.is_transcript_error || false); // Update new state

            setTimestamps(workspaceData.timestamps || '');
            setSummary(workspaceData.summary || '');
            setTakeaways(workspaceData.takeaways || []);
            setTitles(workspaceData.titles || []);
            setHashtags(workspaceData.hashtags || '');
            setisScRun(workspaceData.is_sc_run || false);

            // Save updated data to local storage
            localStorage.setItem(localDataKey, JSON.stringify(workspaceData));
          } else {
            // Document does not exist, clear local storage and reset state
            localStorage.removeItem(localDataKey);

            setWorkspaceTitle('');
            setIsScTimestampsEnabled(false);
            setIsScDescriptionEnabled(false);
            setIsScTakeawaysEnabled(false);
            setIsScTitlesEnabled(false);
            setIsScTagsEnabled(false);
            setIsTranscriptAvailable(false);
            setIsLocalTranscriptAvailable(false); // Reset new state
            setIsYTProcessing(false); // Reset existing state
            setIsLOCALProcessing(false); // Reset new state
            setIsTranscriptError(false); // Reset new state

            setTimestamps('');
            setSummary('');
            setTakeaways([]);
            setTitles([]);
            setHashtags('');
            setisScRun(false);
          }

          setLoading(false);
        },
        (error) => {
          console.error('Error fetching workspace data:', error);
          setLoading(false);
        }
      );

      return () => unsubscribeMeta();
    } else {
      setLoading(false);
    }
  }, [content_id, user]);

  // Detect changes and enable/disable the Save button
  useEffect(() => {
    const localDataKey = `workspace-${content_id}-data`;
    const workspaceData = {
      workspace_title: workspaceTitle,
      is_sc_timestamps_enabled: isScTimestampsEnabled,
      is_sc_description_enabled: isScDescriptionEnabled,
      is_sc_takeaways_enabled: isScTakeawaysEnabled,
      is_sc_titles_enabled: isScTitlesEnabled,
      is_sc_tags_enabled: isScTagsEnabled,
      is_transcript_available: isTranscriptAvailable,
      is_local_transcript_available: isLocalTranscriptAvailable, // Include new field
      is_caption_processing: isYTProcessing, // Existing field
      is_local_caption_processing: isLOCALProcessing, // New field
      is_transcript_error: isTranscriptError, // Include new field
      timestamps,
      summary,
      takeaways,
      titles,
      hashtags,
      is_sc_run: isScRun,
    };

    const hasChanges =
      workspaceTitle !== originalWorkspaceTitle ||
      isScTimestampsEnabled !== originalIsScTimestampsEnabled ||
      isScDescriptionEnabled !== originalIsScDescriptionEnabled ||
      isScTakeawaysEnabled !== originalIsScTakeawaysEnabled ||
      isScTitlesEnabled !== originalIsScTitlesEnabled ||
      isScTagsEnabled !== originalIsScTagsEnabled ||
      isTranscriptAvailable !== originalIsTranscriptAvailable ||
      isLocalTranscriptAvailable !== originalIsLocalTranscriptAvailable || // Compare new field
      isYTProcessing !== originalIsYTProcessing || // Compare existing field
      isLOCALProcessing !== originalIsLOCALProcessing || // Compare new field
      isTranscriptError !== originalIsTranscriptError; // Compare new field

    // Compare additional fields if necessary
    // Add more comparisons if you have more fields

    setIsSaveButtonEnabled(hasChanges);

    // Optionally, you can update local storage whenever there's a change
    localStorage.setItem(localDataKey, JSON.stringify(workspaceData));
  }, [
    workspaceTitle,
    isScTimestampsEnabled,
    isScDescriptionEnabled,
    isScTakeawaysEnabled,
    isScTitlesEnabled,
    isScTagsEnabled,
    isTranscriptAvailable,
    isLocalTranscriptAvailable, // Include new dependency
    isYTProcessing, // Include existing dependency
    isLOCALProcessing, // Include new dependency
    isTranscriptError, // Include new dependency
    timestamps,
    summary,
    takeaways,
    titles,
    hashtags,
    isScRun,
    originalWorkspaceTitle,
    originalIsScTimestampsEnabled,
    originalIsScDescriptionEnabled,
    originalIsScTakeawaysEnabled,
    originalIsScTitlesEnabled,
    originalIsScTagsEnabled,
    originalIsTranscriptAvailable,
    originalIsLocalTranscriptAvailable, // Include new original state
    originalIsYTProcessing, // Include existing original state
    originalIsLOCALProcessing, // Include new original state
    originalIsTranscriptError, // Include new original state
    content_id,
  ]);

  const handleUpdateWorkspace = useCallback(async () => {
    try {
      await updateWorkspace({
        user,
        content_id,
        workspaceTitle,
        isSCTimestampsEnabled: isScTimestampsEnabled,
        isSCDescriptionEnabled: isScDescriptionEnabled,
        isSCTakeawaysEnabled: isScTakeawaysEnabled,
        isSCTitlesEnabled: isScTitlesEnabled,
        isSCTagsEnabled: isScTagsEnabled,
        isTranscriptAvailable,
        isLocalTranscriptAvailable, // Include new field
        isYTProcessing, // Existing field
        isLOCALProcessing, // Include new field
        isTranscriptError, // Include new field
        timestamps,
        summary,
        takeaways,
        titles,
        hashtags,
        isScRun,
      });

      // Update original values to match current values after successful save
      setOriginalWorkspaceTitle(workspaceTitle);
      setOriginalIsScTimestampsEnabled(isScTimestampsEnabled);
      setOriginalIsScDescriptionEnabled(isScDescriptionEnabled);
      setOriginalIsScTakeawaysEnabled(isScTakeawaysEnabled);
      setOriginalIsScTitlesEnabled(isScTitlesEnabled);
      setOriginalIsScTagsEnabled(isScTagsEnabled);
      setOriginalIsTranscriptAvailable(isTranscriptAvailable);
      setOriginalIsLocalTranscriptAvailable(isLocalTranscriptAvailable); // Update original state
      setOriginalIsYTProcessing(isYTProcessing); // Update existing original state
      setOriginalIsLOCALProcessing(isLOCALProcessing); // Update new original state
      setOriginalIsTranscriptError(isTranscriptError); // Update new original state
      // Add any other fields if necessary

      // console.log('Workspace updated successfully.');
    } catch (error) {
      console.error('Error updating workspace:', error);
      // Handle error (e.g., show a notification)
    }
  }, [
    user,
    content_id,
    workspaceTitle,
    isScTimestampsEnabled,
    isScDescriptionEnabled,
    isScTakeawaysEnabled,
    isScTitlesEnabled,
    isScTagsEnabled,
    isTranscriptAvailable,
    isLocalTranscriptAvailable, // Include new dependency
    isYTProcessing, // Include existing dependency
    isLOCALProcessing, // Include new dependency
    isTranscriptError, // Include new dependency
    timestamps,
    summary,
    takeaways,
    titles,
    hashtags,
    isScRun,
  ]);

  return (
    <div className="template-workspace-container">
      {isSidebarOpen ? <Sidebar /> : <CloseSidebar toggleSidebar={toggleSidebar} />}
      <AppTransition>
        <div className="template-workspace-main">
          {loading ? (
            <div>
              <ProcessingPage />
            </div> // Loading state
          ) : (
            <>
              <div className="workspace-header-container"> {/* Wrap Header for centering */}
                <WorkspaceHeader
                  workspaceTitle={workspaceTitle}
                  isTranscriptAvailable={isTranscriptAvailable}
                  isLocalTranscriptAvailable={isLocalTranscriptAvailable} // Pass new prop if needed
                  isTranscriptError={isTranscriptError} // Pass new prop
                  isYTProcessing={isYTProcessing} // Pass existing prop
                  isLOCALProcessing={isLOCALProcessing} // Pass new prop
                  workspaceCreatedFunction={handleUpdateWorkspace}
                  setWorkspaceTitle={setWorkspaceTitle}
                  isSaveButtonEnabled={isSaveButtonEnabled}
                />
              </div>

              <div className="template-row-container"> {/* Ensure content is aligned */}
                <div className="template-upload-container">
                  <UploadWorkspace
                    isTranscriptAvailable={isTranscriptAvailable}
                    isLocalTranscriptAvailable={isLocalTranscriptAvailable} // Pass new prop if needed
                    isYTProcessing={isYTProcessing} // Pass existing prop
                    isLOCALProcessing={isLOCALProcessing} // Pass new prop
                    content_id={content_id}
                  />
                  <div className="modify-shortcuts-container">
                    <ShortcutsWorkspace
                      isScTimestampsEnabled={isScTimestampsEnabled}
                      setIsScTimestampsEnabled={setIsScTimestampsEnabled}
                      isScDescriptionEnabled={isScDescriptionEnabled}
                      setIsScDescriptionEnabled={setIsScDescriptionEnabled}
                      isScTakeawaysEnabled={isScTakeawaysEnabled}
                      setIsScTakeawaysEnabled={setIsScTakeawaysEnabled}
                      isScTitlesEnabled={isScTitlesEnabled}
                      setIsScTitlesEnabled={setIsScTitlesEnabled}
                      isScTagsEnabled={isScTagsEnabled}
                      setIsScTagsEnabled={setIsScTagsEnabled}
                      isTranscriptAvailable={isTranscriptAvailable}
                      isLocalTranscriptAvailable={isLocalTranscriptAvailable} // Pass new prop if needed
                      content_id={content_id}
                      timestamps={timestamps}
                      summary={summary}
                      takeaways={takeaways}
                      titles={titles}
                      hashtags={hashtags}
                    />
                  </div>
                </div>
              </div>

              <div className="template-prompts-container">
                <PromptsWorkspace
                  isTranscriptAvailable={isTranscriptAvailable}
                  isLocalTranscriptAvailable={isLocalTranscriptAvailable} // Pass new prop if needed
                  isYTProcessing={isYTProcessing} // Pass existing prop
                  isLOCALProcessing={isLOCALProcessing} // Pass new prop
                  content_id={content_id}
                  timestamps={timestamps}
                  summary={summary}
                  takeaways={takeaways}
                  titles={titles}
                  hashtags={hashtags}
                  isScRun={isScRun}
                  isScTimestampsEnabled={isScTimestampsEnabled}
                  isScDescriptionEnabled={isScDescriptionEnabled}
                  isScTakeawaysEnabled={isScTakeawaysEnabled}
                  isScTitlesEnabled={isScTitlesEnabled}
                  isScTagsEnabled={isScTagsEnabled}
                />
              </div>
            </>
          )}
        </div>
      </AppTransition>
    </div>
  );
};

export default ExistingWorkspace;
