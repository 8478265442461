import React, { useState, useEffect } from 'react';
import './CookieBar.css';

const CookieBar = () => {
  const [showBar, setShowBar] = useState(true);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    const laterTime = localStorage.getItem('laterTime');
  
    if (consent === 'accepted') {
      setShowBar(false);
    } else if (consent === 'later' && laterTime) {
      const currentTime = new Date().getTime();
      const timePassed = (currentTime - laterTime) / (1000 * 60); // time in minutes
  
      if (timePassed >= 5) {
        setShowBar(true);
      } else {
        setShowBar(false);
      }
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setShowBar(false);
    // Optionally, update Firebase
  };

  const handleLater = () => {
    localStorage.setItem('cookieConsent', 'later');
    const currentTime = new Date().getTime();
    localStorage.setItem('laterTime', currentTime);
    setShowBar(false);

    // Set a timeout to show the bar again after 10 seconds
    setTimeout(() => {
      setShowBar(true);
    }, 300000);  // 5 minutes in milliseconds

    // Optionally, update Firebase
  };

  return (
    showBar && (
      <div className="cookie-bar">
        <div className="cookie-content-wrapper">
          <p className="cookie-text">
            Our website uses cookies. By continuing, we assume your permission to deploy cookies as detailed in our <a href="/privacy-policy">Privacy Policy</a>.
          </p>
          <div className="button-wrapper">
            <button className="accept-cookies-btn" onClick={handleAccept}>Accept Cookies</button>
            <button className="later-btn" onClick={handleLater}>Later</button>
          </div>
        </div>
      </div>
    )
  );
  
};

export default CookieBar;
