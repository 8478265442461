import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import './connectedAccounts.css';
import { auth } from '../../firebase';
import { CLIENT_ID, SCOPES } from '../../googleOAuth';
import axios from 'axios';

import utubeDisconnect from '../../assets/images/utube-dark.png';
import utubeLogoConnected from '../../assets/images/utube-red.png';
import languageIcon from '../../assets/images/langworld-v1.png';
import bumpUPlogo from '../../assets/images/bumparrow.png';



const ConnectedAccounts = ({ profileImage, accessToken }) => {
    const [isConnected, setIsConnected] = useState(false);
    const [channelHandle, setChannelHandle] = useState('');

    useEffect(() => {
        if (!accessToken) {
            setIsConnected(false);
            return;
        }

        setIsConnected(true);

        const checkAndFetchChannelHandle = async () => {
            const user = auth.currentUser;
            if (user) {
                const storedHandle = checkLocalStorage(user.uid);
                if (storedHandle) {
                    // console.log('Channel handle found in local storage:', storedHandle);
                    setChannelHandle(storedHandle);
                } else {
                    // console.log('No channel handle found in local storage, fetching from YouTube API...');
                    await fetchChannelDetails(user.uid);
                }
            } else {
                // console.error('No authenticated user found.');
            }
        };

        const checkLocalStorage = (userId) => {
            const storageKeys = Object.keys(localStorage);
            const relevantKey = storageKeys.find(key => key.startsWith(`${userId}_`) && key.endsWith('_YThandle'));
            return relevantKey ? localStorage.getItem(relevantKey) : null;
        };

        const fetchChannelDetails = async (userId) => {
            try {
                const response = await axios.get(`https://www.googleapis.com/youtube/v3/channels`, {
                    params: {
                        part: 'snippet',
                        mine: true,  // This assumes that you are fetching details for the authenticated user's channel
                        access_token: accessToken,
                    }
                });

                if (response.data.items.length > 0) {
                    const channel = response.data.items[0];
                    const handle = channel.snippet.customUrl || channel.snippet.title;
                    const channelId = channel.id;

                    // Construct a specific local storage key using userId and channelId
                    const youtubeChannelHandleKey = `${userId}_${channelId}_YThandle`;

                    // console.log('Storing new channel handle in local storage:', handle);
                    localStorage.setItem(youtubeChannelHandleKey, handle);
                    setChannelHandle(handle);
                }
            } catch (error) {
                // console.error('Error fetching YouTube channel details:', error);
            }
        };

        checkAndFetchChannelHandle();  // Check local storage and fetch if necessary

    }, [accessToken]); // Only re-run the effect if accessToken changes

    const handleConnectYouTube = () => {
        const user = auth.currentUser;
        const userId = user ? user.uid : null;

        const connectYoutubeUrl = `${process.env.REACT_APP_FUNCTIONS_ENDPOINT}/connectYoutube`;

        if (userId) {
            const authUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(connectYoutubeUrl)}&scope=${encodeURIComponent(SCOPES)}&response_type=code&access_type=offline&state=${userId}`;
            window.location.href = authUrl;
            // console.log('Redirecting to YouTube for authentication...');
        } else {
            // console.error("User not authenticated");
        }
    };

    const title = "Bump AI For Local Videos";
    const description = "Get your video’s timestamps, description, titles, and hashtags";
    const link = "https://intercom.help/bumpups/en/articles/9742280-how-does-bump-ai-process-my-video";

    const openLink = () => {
        window.open(link, '_blank');
    };

    return (
        <div className="connectui-info-box">
            <div className="connectui-center-section">
                <img src={profileImage} alt="Creator Avatar" className="connectui-avatar" />
                <div className="connectui-wrapper">
                <div className="connectui-distribution-title">Platforms</div>
                <button
                    className={`connectui-connectYouTube-button ${isConnected ? 'connected' : ''}`}
                    onClick={() => isConnected ? window.open(`https://www.youtube.com/${channelHandle}`, '_blank') : handleConnectYouTube()}
                    style={{ cursor: isConnected ? 'pointer' : 'pointer' }}
                >
                    <img
                        src={isConnected ? utubeLogoConnected : utubeDisconnect}
                        alt="YouTube Button"
                        className="connectui-connectyoutube"
                    />
                </button>
                    {isConnected && channelHandle && (
                        <div className="connectui-channel-handle">
                        {channelHandle}
                        </div>
                    )}
                </div>
            </div>
            <div className="connectui-processing-container">
                <div className="connectui-processing-card">
                    <div className="connectui-processing-content">
                        <div className="connectui-subtitle">How it works?</div>
                        <div className="connectui-title">{title}</div>
                        <div className="connectui-description">{description}</div>
                        <div className="connectui-processing-actions">
                    <button className="connectui-processing-start-btn" onClick={openLink}>READ NOW</button>
                </div>
                    </div>
                    <div className="connectui-processing-icon">
                        <img className="connectui-upload-icon" src={bumpUPlogo} alt={title} />
                    </div>
                </div>
            </div>

{/*             
            <div className="connectui-processing-container">
                <div className="connectui-processing-card">
                    <div className="connectui-processing-content">
                        <div className="connectui-title">Choose Your Language</div>
                        <div className="connectui-description">Set timestamps, descriptions, and titles in your preferred language.</div>
                        <Link to="/settings" className="link-no-underline">
                        <button className="connectui-processing-start-btn">CHANGE LANGUAGE</button>
                        </Link>
                    </div>
                    <div className="connectui-processing-icon">
                        <img className="connectui-upload-language" src={languageIcon} alt={title} />
                    </div>
                </div>
            </div> */}


        </div>
    );
}

export default ConnectedAccounts;
