// src/components/TemplateWorkspace/createWorkspace.js
import { getFunctions, httpsCallable } from 'firebase/functions';

// Initialize Firebase Functions
const functions = getFunctions();
const createWorkspaceCallable = httpsCallable(functions, 'createWorkspace');

/**
 * Function to create a new workspace via Firebase Cloud Functions
 * @param {Object} params - Parameters for workspace creation
 * @returns {Promise<string>} - Returns the content_id upon success
 */
const createWorkspace = async ({
  user,
  workspaceTitle,
  isScTimestampsEnabled,
  isScDescriptionEnabled,
  isScTakeawaysEnabled,
  isScTitlesEnabled,
  isScTagsEnabled,
  prompt1Input,
  prompt2Input,
  prompt3Input
}) => {

    // Prepare workspace data
    const workspaceData = {
        createdBy: user.uid,
        workspaceTitle: workspaceTitle,
        isSCTimestampsEnabled: isScTimestampsEnabled,
        isSCDescriptionEnabled: isScDescriptionEnabled,
        isSCTakeawaysEnabled: isScTakeawaysEnabled,
        isSCTitlesEnabled: isScTitlesEnabled,
        isSCTagsEnabled: isScTagsEnabled,
        prompt1Input: prompt1Input,
        prompt2Input: prompt2Input,
        prompt3Input: prompt3Input,
        isTranscriptAvailable: false, // Default value
    };

    try {
        // Call Firebase function to create workspace
        const response = await createWorkspaceCallable({ workspaceData });
        // console.log('Workspace created successfully. Response:', response.data);

        // Clear local storage cache for the user's workspace videos
        const localStorageKey = `videoCache_${user.uid}_WorkspaceVideos`;
        localStorage.removeItem(localStorageKey);
        // console.log(`Cleared localStorage cache for key: ${localStorageKey}`);

        return response.data.content_id; // Ensure your Firebase function returns the content_id
    } catch (error) {
        // console.error('Error creating workspace:', error);
        throw error;
    }
};

export { createWorkspace };
