import React from 'react';
import './promptOutputRender.css'; // Your custom CSS file
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm'; // Import remark-gfm
import copyIcon from '../../assets/images/copyicon-v1.png';
import checkmarkIcon from '../../assets/images/checkmark-v1.png';

const PromptOutput = ({ promptKey, promptOutput, isCopiedOutput, handleCopy }) => {
  return (
    <div className="prompt-output">
      <div className="prompt-output-text">
        <button
          className="prompt-copyButton"
          onClick={() => handleCopy(promptKey, promptOutput, 'output')}
          aria-label="Copy Output"
        >
          {isCopiedOutput ? (
            <img src={checkmarkIcon} alt="Copied" className="prompt-checkmarkIcon" />
          ) : (
            <img src={copyIcon} alt="Copy" className="prompt-copyIcon" />
          )}
        </button>
        <ReactMarkdown
          className="prompt-markdown"
          remarkPlugins={[remarkGfm]} // Add the GFM plugin
          components={{
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || '');
              return !inline && match ? (
                <SyntaxHighlighter
                  style={dracula}
                  language={match[1]}
                  PreTag="div"
                  {...props}
                >
                  {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            },
            a: ({ node, ...props }) => (
              <a {...props} target="_blank" rel="noopener noreferrer">
                {props.children}
              </a>
            ),
          }}
        >
          {promptOutput}
        </ReactMarkdown>
      </div>
    </div>
  );
};

export default PromptOutput;
