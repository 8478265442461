// src/components/WorkspaceDashboard/workspaceDashboard.js

import React from 'react';
import './workspaceDashboard.css';
import ListViewWorkspace from './listviewWorkspace'; // Adjust the path as necessary
import Sidebar from '../myDashboard/Sidebar';
import CloseSidebar from '../myDashboard/CloseSidebar';
import AppTransition from '../components/AppTransition'; // Ensure this import path is correct

const WorkspaceDashboard = ({ isSidebarOpen, toggleSidebar }) => {
  return (
    <div className="workspace-dashboard-container">
      {isSidebarOpen ? <Sidebar /> : <CloseSidebar toggleSidebar={toggleSidebar} />}
      <AppTransition>
        <div className="workspace-dashboard-main">
          <div className="workspace-dashboard-content-wrapper">
            <div className="workspace-dashboard-contentgeneral">
            </div>
            <ListViewWorkspace />
          </div>
        </div>
      </AppTransition>
    </div>
  );
};

export default WorkspaceDashboard;
