import React, { useState } from 'react';
import './FAQComponent.css';

const FAQComponent = () => {
  const [faqOpen, setFaqOpen] = useState([false, false, false, false, false, false]);

  const toggleFaq = (index) => {
    setFaqOpen(faqOpen.map((isOpen, i) => (i === index ? !isOpen : isOpen)));
  };

  return (
    <div className="faq-section-pricing">
      <h1>Frequently Asked Questions</h1>

      <div className="faq-item" onClick={() => toggleFaq(0)}>
        <h3>
          Is Bumpups free to use?
          <span className={faqOpen[0] ? "caret rotate" : "caret"}>⌄</span>
        </h3>
        <div className={faqOpen[0] ? "faq-content show" : "faq-content"}>
          <b>Yes</b>, Bumpups offers 60 minutes of free processing, and no credit card is required to start. <br /><br />
          Simply create an account to begin using all features. To continue beyond the free plan, you can upgrade for more video processing hours each month.
        </div>
      </div>

      <div className="faq-item" onClick={() => toggleFaq(1)}>
        <h3>
          What can Bumpups do for my video?
          <span className={faqOpen[1] ? "caret rotate" : "caret"}>⌄</span>
        </h3>
        <div className={faqOpen[1] ? "faq-content show" : "faq-content"}>
          Bumpups processes your video based on your input, providing insights like summaries, analyses, and industry-specific information. <br /><br />
          You can also use shortcuts to quickly generate specific outputs, such as timestamps, from your requests.
        </div>
      </div>

      <div className="faq-item" onClick={() => toggleFaq(2)}>
        <h3>
          What are Bump shortcuts?
          <span className={faqOpen[2] ? "caret rotate" : "caret"}>⌄</span>
        </h3>
        <div className={faqOpen[2] ? "faq-content show" : "faq-content"}>
          Bump shortcuts are prebuilt tools that let you quickly generate specific outputs, such as timestamps, descriptions, titles, and tags, based on your input, without needing to manually request each feature in detail.
        </div>
      </div>

      <div className="faq-item" onClick={() => toggleFaq(3)}>
        <h3>
          How does Bump AI process my video?
          <span className={faqOpen[3] ? "caret rotate" : "caret"}>⌄</span>
        </h3>
        <div className={faqOpen[3] ? "faq-content show" : "faq-content"}>
          Bump AI processes your video by analyzing its content based on the questions or prompts you provide, extracting relevant insights such as summaries, key points, or industry-specific analyses.<br /><br />
          It uses advanced algorithms to understand the video’s context and deliver tailored outputs according to your input.
        </div>
      </div>

      <div className="faq-item" onClick={() => toggleFaq(4)}>
        <h3>
          What video durations can the platform support?
          <span className={faqOpen[4] ? "caret rotate" : "caret"}>⌄</span>
        </h3>
        <div className={faqOpen[4] ? "faq-content show" : "faq-content"}>
          Our platform supports videos ranging from <b>30 seconds to 3.5 hours</b> in length. We are actively working on extending this range to accommodate longer videos.
        </div>
      </div>

      <div className="faq-item" onClick={() => toggleFaq(5)}>
        <h3>
          Can I upload local files?
          <span className={faqOpen[5] ? "caret rotate" : "caret"}>⌄</span>
        </h3>
        <div className={faqOpen[5] ? "faq-content show" : "faq-content"}>
          <b>Yes</b>, with the Bump Plan, the processing of local video files is now supported.
        </div>
      </div>
    </div>
  );
};

export default FAQComponent;
