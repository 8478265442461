// src/components/UploadWorkspace/YTVideoProcessor.js

import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom'; 
import axios from 'axios';
import { getYoutubeWORKSPACEcaptions } from '../../../../myCreator/getVideoCaptions';
import { auth, db } from '../../../../firebase'; // Ensure auth and db are imported
import { doc, getDoc } from 'firebase/firestore'; // Import necessary Firestore functions
import processBumpupslogo from '../../../../assets/gifmedia/bumpups-2.gif';
import placeholderThumbnail from '../../../../assets/images/tube_previewthumbnail.png';
import linkIcon from '../../../../assets/images/link-v3.png';
import basePlanLogo from '../../../../assets/images/baseplan-white.png';
import './linkWorkspace.css'; // Shared CSS

const YTVideoProcessor = ({
    workspaceVideoDetails,
    setWorkspaceVideoDetails,
    setDoneVideos,
    doneVideos,
    onInputChange,
    content_id,
    isTranscriptAvailable,
    isYTProcessing,
}) => {
    const [workspaceInputUrl, setWorkspaceInputUrl] = useState('');
    const [captionsAvailable, setCaptionsAvailable] = useState(false);
    const [isOverTimebank, setIsOverTimebank] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [istoLong, setIstoLong] = useState(false);
    const [noValidVideo, setNoValidVideo] = useState(false);
    const [showVideoNotFound, setShowVideoNotFound] = useState(false);
    const [remainingTime, setRemainingTime] = useState({});
    const [isFinalizing, setIsFinalizing] = useState({});
    const [processingStatus, setProcessingStatus] = useState({});
    const [videoProcessingError, setVideoProcessingError] = useState({});
    const countdownRefsRef = useRef({});

    // Function to extract video ID from URLs
    const extractVideoID = (url) => {
        const youtubeRegExp = /^.*(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|&v=))([^#&?]*).*/;
        const youtubeMatch = url.match(youtubeRegExp);
        if (youtubeMatch?.[1] && /^[a-zA-Z0-9_-]{11}$/.test(youtubeMatch[1])) {
            return youtubeMatch[1];
        }
      
        const workspaceRegExp = /^.*(?:workspace\.com\/video\/)([^#&?]*).*/;
        const workspaceMatch = url.match(workspaceRegExp);
        if (workspaceMatch?.[1] && /^[a-zA-Z0-9_-]{11}$/.test(workspaceMatch[1])) {
            return workspaceMatch[1];
        }
      
        return null;
    };

    // Function to convert ISO 8601 duration to seconds
    const convertDurationToSeconds = (duration) => {
        if (!duration) return 0;
        let match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
        if (!match) return 0;
        let hours = match[1] ? parseInt(match[1].slice(0, -1), 10) : 0;
        let minutes = match[2] ? parseInt(match[2].slice(0, -1), 10) : 0;
        let seconds = match[3] ? parseInt(match[3].slice(0, -1), 10) : 0;
        return hours * 3600 + minutes * 60 + seconds;
    };

    // Function to fetch video details using YouTube API
    const fetchWorkspaceVideoDetails = async (url) => {
        const videoId = extractVideoID(url);
        if (!videoId) {
            setNoValidVideo(true);
            setShowVideoNotFound(true); // Ensure the "Video Not Found" message is displayed
            return;
        }

        setIsSearching(true);
        const apiWorkspaceKey = process.env.REACT_APP_YOUTUBE_API_KEY; // Ensure this is set in your .env file

        try {
            const response = await axios.get(`https://www.googleapis.com/youtube/v3/videos`, {
                params: {
                    part: 'snippet,contentDetails,status', // Added 'status' to get visibility
                    id: videoId,
                    key: apiWorkspaceKey,
                },
            });

            if (response.data.items.length > 0) {
                const videoItem = response.data.items[0];
                const { snippet, contentDetails, status } = videoItem;

                // Check if all necessary fields are present
                if (!snippet || !contentDetails || !status) {
                    setNoValidVideo(true);
                    setShowVideoNotFound(true);
                    setIsSearching(false);
                    return;
                }

                const durationSeconds = convertDurationToSeconds(contentDetails.duration);
                setIstoLong(durationSeconds > 12600); // 3.5 hours in seconds

                // Fallback chain for thumbnail resolution
                const thumbnailUrl =
                    snippet.thumbnails?.maxres?.url ||
                    snippet.thumbnails?.standard?.url ||
                    snippet.thumbnails?.high?.url;

                if (!thumbnailUrl) {
                    setIsSearching(false);
                    setNoValidVideo(true);
                    setShowVideoNotFound(true);
                    return;
                }

                const language = snippet.defaultLanguage || 'Language unspecified';
                const visibility = status.privacyStatus; // 'public', 'private', 'unlisted'

                if (visibility === 'public') {
                    // Check for captions availability
                    let captionsFound = false;
                    try {
                        const captionsResponse = await axios.get(`https://www.googleapis.com/youtube/v3/captions`, {
                            params: {
                                part: 'snippet',
                                videoId: videoId,
                                key: apiWorkspaceKey,
                            },
                        });

                        captionsFound = captionsResponse.data.items.length > 0;
                    } catch (captionError) {
                        // Captions might not be available or another error occurred
                        captionsFound = false;
                    }

                    setCaptionsAvailable(captionsFound);
                } else {
                    // For private or unlisted videos, assume captions are available
                    setCaptionsAvailable(true);
                }

                const workspaceDetails = {
                    thumbnailUrl: thumbnailUrl,
                    duration: contentDetails.duration,
                    durationSeconds: durationSeconds,
                    language: language,
                    captionAvailable: captionsAvailable, // Updated based on actual captions availability
                    visibility: visibility,
                    videoId: videoId,
                    title: snippet.title,
                };

                setWorkspaceVideoDetails(workspaceDetails);
                setIsSearching(false);
                onInputChange(true);
            } else {
                setNoValidVideo(true);
                setShowVideoNotFound(true); // Ensure the "Video Not Found" message is displayed
                setIsSearching(false);
            }
        } catch (error) {
            // Handle errors gracefully
            // console.error('Error fetching workspace video details:', error);
            setNoValidVideo(true);
            setShowVideoNotFound(true); // Ensure the "Video Not Found" message is displayed
            setIsSearching(false);
        }
    };

    useEffect(() => {
        const fetchTimebank = async () => {
            const user = auth.currentUser;
            if (user) {
                const userDocRef = doc(db, 'users', user.uid);
                const timebankRef = doc(userDocRef, 'subscriptionData', 'timeWarden');
    
                try {
                    const docSnap = await getDoc(timebankRef);
                    if (docSnap.exists()) {
                        const { timeBank, timeBankBoost, timeBankBump } = docSnap.data();
    
                        const timebankInSeconds = (timeBank || 0) * 60;
                        const timeBankBoostInSeconds = (timeBankBoost || 0) * 60;
                        const timeBankBumpInSeconds = (timeBankBump || 0) * 60;
    
                        const totalTimebankInSeconds = timebankInSeconds + timeBankBoostInSeconds + timeBankBumpInSeconds;
    
                        if (workspaceVideoDetails.durationSeconds) {
                            const videoDurationInSeconds = workspaceVideoDetails.durationSeconds;
    
                            if (totalTimebankInSeconds >= videoDurationInSeconds) {
                                setIsOverTimebank(false);
                            } else {
                                setIsOverTimebank(true);
                            }
                        }
                    }
                } catch (error) {
                    // Handle error appropriately
                    // console.error('Error fetching timebank data:', error);
                }
            }
        };
    
        fetchTimebank();
    }, [workspaceVideoDetails]);

    // Handle URL input changes
    const handleUrlChange = async (url) => {
        const videoId = extractVideoID(url);
        setWorkspaceInputUrl(url);
        setNoValidVideo(false);
        setIstoLong(false);
        setIsOverTimebank(false);
        setShowVideoNotFound(false); // Reset before processing

        if (videoId) {
            await fetchWorkspaceVideoDetails(url);
        } else {
            setWorkspaceVideoDetails({});
            setShowVideoNotFound(true);
            if (url.trim() !== '') {
                onInputChange(true);
            } else {
                onInputChange(false);
            }
        }
    };

    // Function to format time for countdown
    const formatTime = (seconds) => {
        if (seconds >= 60) {
            const minutes = Math.floor(seconds / 60);
            const remainingSeconds = seconds % 60;
            if (remainingSeconds === 0) {
                return `${minutes} minutes left`;
            }
            return `${minutes}m ${remainingSeconds}s left`;
        }
        return `${seconds} seconds left`;
    };

    // Function to format duration
    const formatDuration = (duration) => {
        if (!duration) return '0m';
        if (typeof duration === 'number') {
            const hours = Math.floor(duration / 3600);
            const minutes = Math.floor((duration % 3600) / 60);
            let formatted = '';
            if (hours > 0) formatted += `${hours}h `;
            formatted += `${minutes}m`;
            return formatted.trim();
        } else {
            const match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
            let hours = match?.[1] ? parseInt(match[1].slice(0, -1), 10) : 0;
            let minutes = match?.[2] ? parseInt(match[2].slice(0, -1), 10) : 0;
            let formatted = '';
            if (hours > 0) formatted += `${hours}h `;
            if (minutes > 0 || (minutes === 0 && hours === 0)) formatted += `${minutes}m`;
            return formatted.trim();
        }
    };

    // Function to get language name
    const getLanguageName = (languageCode) => {
        if (!languageCode || languageCode === 'Language unspecified') {
            return null;
        }
      
        try {
            const languageNames = new Intl.DisplayNames(['en'], { type: 'language' });
            return languageNames.of(languageCode);
        } catch (error) {
            return null;
        }
    };

    // Handle Workspace URL processing with countdown and visibility
    const handleWorkspaceURLProcessing = async () => {
        const user = auth.currentUser;
        const videoId = workspaceVideoDetails.videoId;

        if (!videoId) {
            setShowVideoNotFound(true);
            return;
        }

        setIsSearching(true);
        let durationInSeconds = workspaceVideoDetails.durationSeconds || convertDurationToSeconds(workspaceVideoDetails.duration);
        const fullUrl = workspaceInputUrl;
        const visibility = workspaceVideoDetails.visibility;
        const isPublic = visibility === 'public';
    
        setProcessingStatus(prevStatus => ({ ...prevStatus, [videoId]: true }));
        const processingTimeSeconds = Math.ceil(durationInSeconds / 360);
        setRemainingTime(prev => ({ ...prev, [videoId]: processingTimeSeconds }));
        setIsFinalizing(prev => ({ ...prev, [videoId]: false }));
    
        // Start Countdown (existing logic)
        if (!countdownRefsRef.current[videoId]) {
            countdownRefsRef.current[videoId] = setInterval(() => {
                setRemainingTime(prev => {
                    const newTime = prev[videoId] - 1;
                    if (newTime <= 0) {
                        clearInterval(countdownRefsRef.current[videoId]);
                        delete countdownRefsRef.current[videoId];
                        setIsFinalizing(prevFinalizing => ({
                            ...prevFinalizing,
                            [videoId]: true,
                        }));
                        return {
                            ...prev,
                            [videoId]: 0,
                        };
                    }
                    return {
                        ...prev,
                        [videoId]: newTime,
                    };
                });
            }, 1000);
        }
    
        try {
            const response = await getYoutubeWORKSPACEcaptions({
                videoId,
                durationInSeconds,
                thumbnailUrl: workspaceVideoDetails.thumbnailUrl,
                title: workspaceVideoDetails.title,
                isReprocess: false,
                fullUrl,
                isPublic,
                content_id,
                isWorkspace: true
            });

            const { message } = response;

            if (message === 'success') {
                const localStorageKey = `videoCache_${user.uid}_WorkspaceVideos`;
                localStorage.removeItem(localStorageKey);
                setRemainingTime(prev => ({ ...prev, [videoId]: null }));
                setIsFinalizing(prev => ({ ...prev, [videoId]: false }));
                setProcessingStatus(prevStatus => ({ ...prevStatus, [videoId]: false }));
                setIsSearching(false);
                setDoneVideos(prevDone => [...prevDone, videoId]);

            } else {
                let errorMessage = '';
                switch (message) {
                    case 'captions_not_available':
                        errorMessage = 'Captions not available for this video.';
                        break;
                    case 'captions_not_available_in_language':
                        errorMessage = 'Captions not available in your preferred language.';
                        break;
                    default:
                        errorMessage = 'Processing took longer than usual...';
                }

                setVideoProcessingError(prevErrors => ({
                    ...prevErrors,
                    [videoId]: errorMessage,
                }));
                setIsSearching(false);
                setProcessingStatus(prevStatus => ({ ...prevStatus, [videoId]: false }));
                setRemainingTime(prev => ({ ...prev, [videoId]: null }));
                setIsFinalizing(prev => ({ ...prev, [videoId]: false }));

            }
        } catch (error) {
            // Handle errors gracefully
            // console.error('Error during processing:', error);
            setVideoProcessingError(prevErrors => ({
                ...prevErrors,
                [videoId]: 'An internal error occurred during processing.',
            }));
            setProcessingStatus(prevStatus => ({ ...prevStatus, [videoId]: false }));
            setRemainingTime(prev => ({ ...prev, [videoId]: null }));
            setIsFinalizing(prev => ({ ...prev, [videoId]: false }));
            setIsSearching(false);

        }
    
        // Timeout to handle cases where processing takes too long (3 minutes)
        setTimeout(async () => {
            const vid = workspaceVideoDetails.videoId;
            if (!vid) return; // Ensure videoId exists
            setVideoProcessingError(prevErrors => ({
                ...prevErrors,
                [vid]: 'Processing is taking longer than usual... ⏳',
            }));
            setProcessingStatus(prevStatus => ({ ...prevStatus, [vid]: false }));
            setIsSearching(false);
            clearInterval(countdownRefsRef.current[vid]);
            delete countdownRefsRef.current[vid];
            setRemainingTime(prev => ({ ...prev, [vid]: null }));
            setIsFinalizing(prev => ({ ...prev, [vid]: false }));
        }, 180000); // 3 minutes
    };

    // useEffect to handle countdown for each videoId
    useEffect(() => {
        Object.keys(remainingTime).forEach(videoId => {
            if (remainingTime[videoId] > 0 && !countdownRefsRef.current[videoId]) {
                countdownRefsRef.current[videoId] = setInterval(() => {
                    setRemainingTime(prev => {
                        const newTime = prev[videoId] - 1;
                        if (newTime <= 0) {
                            clearInterval(countdownRefsRef.current[videoId]);
                            delete countdownRefsRef.current[videoId];
                            setIsFinalizing(prevFinalizing => ({
                                ...prevFinalizing,
                                [videoId]: true,
                            }));
                            return {
                                ...prev,
                                [videoId]: 0,
                            };
                        }
                        return {
                            ...prev,
                            [videoId]: newTime,
                        };
                    });
                }, 1000);
            }
        });
    }, [remainingTime]);

    // Cleanup on unmount
    useEffect(() => {
        const currentCountdowns = { ...countdownRefsRef.current };
        return () => {
            Object.values(currentCountdowns).forEach(interval => clearInterval(interval));
        };
    }, []);

    // Handle input change cleanup
    useEffect(() => {
        if (workspaceInputUrl.trim() === '') {
            onInputChange(false);
        }
    }, [workspaceInputUrl, onInputChange]);

    return (
        <>
            <section className="worklink-urlinput-section">
                <div className="worklink-urlinput-container">
                        <div className="worklink-input-icon-wrapper">
                            <img src={linkIcon} alt="Link Icon" className="worklink-url-input-icon" />
                            <input
                                type="text"
                                id="urlInput"
                                className="worklink-url-input-box"
                                placeholder={isYTProcessing ? "Bumpups is uploading..." : "Drop a YouTube link..."}
                                value={workspaceInputUrl}
                                onChange={(e) => handleUrlChange(e.target.value)}
                                disabled={isYTProcessing || isTranscriptAvailable} // Disables input if isProcessing is true
                            />
                        </div>
                    <div>
                        {!processingStatus[workspaceVideoDetails.videoId] &&
                            !doneVideos.includes(workspaceVideoDetails.videoId) &&
                            !isOverTimebank &&
                            !istoLong && 
                            !isYTProcessing && 
                            captionsAvailable && (
                                <button
                                    className={`worklink-url-input-button 
                                              ${!workspaceInputUrl || !extractVideoID(workspaceInputUrl) || isSearching || noValidVideo ? 'disabled' : ''} 
                                              ${isSearching ? 'searching' : ''}`}
                                    onClick={handleWorkspaceURLProcessing}
                                    disabled={!workspaceInputUrl || !extractVideoID(workspaceInputUrl) || isSearching || noValidVideo}
                                >
                                    <img src={basePlanLogo} alt="Base Plan Logo" className="icon" />
                                    {noValidVideo ? 'Not Valid URL' : (isSearching ? 'Searching...' : 'Upload Video')}
                                </button>
                            )}

                        {/* "No Captions" Button */}
                        {!processingStatus[workspaceVideoDetails.videoId] &&
                            !doneVideos.includes(workspaceVideoDetails.videoId) &&
                            !isOverTimebank &&
                            !istoLong &&
                            !captionsAvailable &&
                            workspaceVideoDetails.videoId && (
                                <button className="worklink-url-input-button processing" disabled>
                                    <img src={basePlanLogo} alt="Base Plan Logo" className="icon" />
                                    No Captions
                                </button>
                            )}

                            {(processingStatus[workspaceVideoDetails.videoId] || isYTProcessing) && (
                                <button className="worklink-url-input-button processing" disabled>
                                    <img src={basePlanLogo} alt="Base Plan Logo" className="icon" />
                                    {isFinalizing[workspaceVideoDetails.videoId]
                                        ? 'Finalizing Upload..'
                                        : remainingTime[workspaceVideoDetails.videoId]
                                            ? formatTime(remainingTime[workspaceVideoDetails.videoId])
                                            : 'Processing...'}
                                </button>
                            )}

                        {isOverTimebank && !istoLong && !doneVideos.includes(workspaceVideoDetails.videoId) && (
                            <Link to="/plans" className="worklink-url-input-button timebank-over">
                                <img src={basePlanLogo} alt="Base Plan Logo" className="icon" />
                                Get More Time
                            </Link>
                        )}

                        {istoLong && !doneVideos.includes(workspaceVideoDetails.videoId)  && (
                            <button className="worklink-url-input-button max-duration-warning" disabled>
                                <img src={basePlanLogo} alt="Base Plan Logo" className="icon" />
                                Max Duration 3h 30m
                            </button>
                        )}
                    </div>
                </div>
            </section>

            {/* Video Details */}
            {workspaceInputUrl && workspaceVideoDetails.thumbnailUrl && (
                <section className="worklink-workspace-video-details">
                    <div className="worklink-video-card">
                        <div className="worklink-video-metadata">
                            {workspaceVideoDetails.language && workspaceVideoDetails.language !== 'Language unspecified' ? (
                                <div className="worklink-video-caption">
                                    Caption: <b>{getLanguageName(workspaceVideoDetails.language)}</b>
                                </div>
                            ) : (
                                <div className="worklink-video-caption" aria-hidden="true">&#160;</div>
                            )}
                            <div className="worklink-video-credit-usage">
                                <span className="badge-base">Base</span> Credit Cost: <b>{
                                    workspaceVideoDetails.durationSeconds
                                        ? formatDuration(workspaceVideoDetails.durationSeconds)
                                        : formatDuration(workspaceVideoDetails.duration)
                                }</b>
                            </div>
                        </div>
                        <div className="worklink-video-overlay">
                            {/* Placeholder for potential overlay content */}
                        </div>

                        <div className="worklink-video-thumbnail-container" style={{ position: 'relative' }}>
                            <img 
                                src={workspaceVideoDetails.thumbnailUrl || placeholderThumbnail} 
                                alt="Video Thumbnail" 
                                className="worklink-video-thumbnail"
                            />

                        {(processingStatus[workspaceVideoDetails.videoId] || isYTProcessing) && (
                                <>
                                    <div className="worklink-video-thumbnail-overlay"></div> 
                                    <img 
                                        src={processBumpupslogo} 
                                        alt="Processing" 
                                        className="worklink-processing-gif" 
                                        style={{ 
                                            position: 'absolute', 
                                            top: '50%', 
                                            left: '50%', 
                                            transform: 'translate(-50%, -50%)', 
                                            zIndex: 2 
                                        }} 
                                    />
                                </>
                            )}

                            {videoProcessingError[workspaceVideoDetails.videoId] &&
                                !doneVideos.includes(workspaceVideoDetails.videoId) && (
                                    <div className="worklink-error-video-thumbnail-overlay">
                                        <div className="worklink-error-message-box">
                                            {videoProcessingError[workspaceVideoDetails.videoId] ===
                                            'captions_not_available' ? (
                                                <div className="worklink-error-message">
                                                    Captions not available for this video.
                                                </div>
                                            ) : videoProcessingError[workspaceVideoDetails.videoId] ===
                                                'captions_not_available_in_language' ? (
                                                <div className="worklink-error-message">
                                                    Captions not available in your preferred language.
                                                </div>
                                            ) : (
                                                <div className="worklink-error-message">
                                                    Captions not available for this video.
                                                </div>
                                            )}
                                            <div className="worklink-retry-message">
                                                Learn more{' '}
                                                <a
                                                    href="https://intercom.help/bumpups/en/articles/9126173-why-won-t-my-videos-process"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    here
                                                </a>
                                                .
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </div>
                        {workspaceVideoDetails.title && (
                            <div className="worklink-video-title-DONE">
                                <b>
                                    {workspaceVideoDetails.title.length > 66
                                        ? `${workspaceVideoDetails.title.slice(0, 66)}...` 
                                        : workspaceVideoDetails.title}
                                </b>
                            </div>
                        )}
                    </div>
                </section>
            )}

            {
                showVideoNotFound && workspaceInputUrl && (
                    <div className="worklink-video-not-found">
                        <div>
                            <div className="worklink-main-text">Video Not Found 🔎</div>
                            <div className="worklink-small-text">
                                Check your video id:
                                <span className="unauth-example-url">https://www.youtube.com/watch?v=<span className="highlight-video-id">Vevu1P5XGNk</span></span>
                                <br />
                                Supports videos from 4 to 210 minutes ⏲️
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );

};

export default YTVideoProcessor;
