// src/HomePage.js
import React, { useEffect, useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import './HomePage.css';

// Firebase auth and context
import { signInWithRedirect } from 'firebase/auth';
import { AuthContext, AuthProvider } from '../AuthContext';
import { auth, googleProvider } from '../firebase';

// Components and images
import FAQComponent from './FAQComponent';
import ExampleVideoCarousel from './homeComponent/exampleVideoCarousel';
import PodcastUseCase from './homeComponent/podcastUseCase';
import Footer from './Footer';
import CookieBar from '../components/CookieBar';
import SocialProfileCarousel from './homeComponent/socialprofileCarousel';

// Images
import signupGoogle from '../assets/images/signup-goog.png';
import signupGoogleHover from '../assets/images/signup-goog-hover.png'; // Import the hover image
import promptWorkspace from '../assets/images/workspace-topfold.png';

const possibleVideoValue = [
  "Anything",
  "Timestamps",
  "Data Tables",
  "Quick Summaries",
  "Study Guide",
  "SWOT Analysis",
  "Case Brief",
  "Tutorial Steps",
  "Legal Analysis",
  "Coding Steps",
  "Interview Insights",
  "Footnotes",
  "Annotated Excerpts",
  "Code Blocks",
  "Bullet Points",
  "Instructions",
  "Blockquotes",
];

function HomePage() {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [isGoogleHovered, setIsGoogleHovered] = useState(false); // State for hover
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  // Capture and store GCLID
  useEffect(() => {
    const getGCLID = () => {
      const params = new URLSearchParams(window.location.search);
      return params.get('gclid') || '';
    };

    const gclid = getGCLID();
    localStorage.setItem('gclid', gclid);
  }, []);

  // Manage animation classes
  const [animationState, setAnimationState] = useState('fade-in');

  useEffect(() => {
    if (currentUser) {
      navigate('/dashboard');
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimationState('fade-out');
      setTimeout(() => {
        setCurrentTextIndex((prevIndex) => (prevIndex + 1) % possibleVideoValue.length);
        setAnimationState('fade-in');
      }, 500);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleSignUp = () => {
    navigate('/signup');
  };

  const handleGoogleSignUp = async () => {
    try {
      await signInWithRedirect(auth, googleProvider);
    } catch (error) {
      // Handle error (e.g., show notification)
      // console.error("Error signing up with Google:", error);
    }
  };

  return (
    <AuthProvider>
      <Helmet>
        <title>Bumpups: Chat with any YouTube video</title>
        <meta name="description" content="Chat with your videos to deliver insights across all industries." />
      </Helmet>
      <div className="hp-homepage-container">
        
        {/* Top fold section */}
        <div className="hp-topfold-section">
          <div className="hp-topfold-content">
            
            {/* Get Started Pill */}
            <button className="hp-get-started-pill" onClick={handleSignUp}>
              <span className="hp-pill-badge">Free</span>
              <span className="hp-pill-text">#1 AI Video Assistant</span>
              <span className="hp-pill-arrow">→</span>
            </button>
            
            {/* Main Header */}
            <h1 className="hp-main-header">
              Chat with videos for <br /> <span className={`highlight ${animationState}`}>{possibleVideoValue[currentTextIndex]}</span>
            </h1>
            <p className="hp-sub-header">
              Chat with your videos to deliver insights across all industries. Ask questions, request summaries, analyses, and more with <b>Bump AI</b>.
            </p>
            <div className="hp-cta-buttons">
              <button className="hp-cta-button email-button" onClick={handleSignUp}>
                Start free with email
              </button>
              <button 
                className="hp-cta-button hp-google-button" 
                onClick={handleGoogleSignUp}
                onMouseEnter={() => setIsGoogleHovered(true)}
                onMouseLeave={() => setIsGoogleHovered(false)}
              >
                <img 
                  src={isGoogleHovered ? signupGoogleHover : signupGoogle} 
                  alt="Sign up with Google" 
                  className="hp-google-image" 
                />
              </button>
            </div>
          </div>
          <div className="hp-topfold-media-container">
            {/* Autoplaying Video */}
            <video
              className="hp-topfold-video"
              src="https://firebasestorage.googleapis.com/v0/b/bumpups.appspot.com/o/bump-trailer%2Ftrim-demo-bumpups.mp4?alt=media&token=631fc865-d851-403d-8812-5137138b348f"
              autoPlay
              muted
              loop
              playsInline
              poster={promptWorkspace} /* Optional: Add a poster image */
            >
              {/* Your browser does not support the video tag. */}
            </video>
          </div>
        </div>

        {/* Other sections */}
        <ExampleVideoCarousel />
                {/* New Prompt Workspace Image Section */}
         <div className="hp-prompt-workspace-wrapper">
          <h2 className="podcast-use-case-title">Bumpups Workspaces</h2>
          <p className="hp-prompt-workspace-subtitle">
            Explore our unique workspace designed to help you interact with video content and extract insights effortlessly.
          </p>
          <button 
            className="hp-prompt-workspace-container"
            onClick={() => navigate('/signup')}
            style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
          >
            <img 
              src={promptWorkspace} 
              alt="Prompt Workspace" 
              className="hp-prompt-workspace-image" 
            />
          </button>
        </div>
        <PodcastUseCase />


        {/* Social Profile Carousel Section */}
        <SocialProfileCarousel /> 

        {/* Other components */}
        <FAQComponent />
        <CookieBar />
        <Footer />
      </div>
    </AuthProvider>
  );
}

export default HomePage;
