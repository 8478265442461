// examplePrompts.js

const examplePrompts = [
  // General
  { text: "Create a bullet-point list summarizing the main points of the video.", category: "General" },
  { text: "Generate a Markdown table of key takeaways and their implications.", category: "General" },
  { text: "Explain the concepts covered using numbered steps.", category: "General" },
  { text: "Draft an email summarizing the video content, including blockquotes for important quotes.", category: "General" },
  { text: "Provide a detailed summary with headings and subheadings.", category: "General" },
  { text: "List all the topics discussed in order using a numbered list.", category: "General" },
  
  // Education
  { text: "Create quiz questions based on the video, formatted as a list.", category: "Education" },
  { text: "Outline a lesson plan from the video content using Markdown headings.", category: "Education" },
  { text: "Explain the key concepts to a beginner, including code blocks for any formulas.", category: "Education" },
  { text: "Develop a study guide with bullet points and footnotes for additional resources.", category: "Education" },
  { text: "Highlight important dates and events in a Markdown table.", category: "Education" },
  
  // Business
  { text: "Analyze the marketing strategies discussed and present them in a table.", category: "Business" },
  { text: "What are the business growth opportunities mentioned? List them as bullet points.", category: "Business" },
  { text: "Summarize the financial projections presented, including key figures in a table.", category: "Business" },
  { text: "Identify the challenges and solutions discussed using bullet points.", category: "Business" },
  { text: "Extract action items for the team from the meeting recording, formatted as a checklist.", category: "Business" },
  { text: "Provide a SWOT analysis based on the content, using Markdown formatting.", category: "Business" },
  
  // Legal
  { text: "Summarize the legal arguments made, using bullet points and italics for case names.", category: "Legal" },
  { text: "Identify key precedents referenced in the case, formatted with footnotes.", category: "Legal" },
  { text: "Explain the implications of the new legislation discussed, using blockquotes for excerpts.", category: "Legal" },
  { text: "Outline the compliance requirements mentioned, using headings and subheadings.", category: "Legal" },
  { text: "Provide a brief of the legal case presented, highlighting important sections in bold.", category: "Legal" },
  
  // Real Estate
  { text: "Provide an overview of the property market trends mentioned, including statistics in a table.", category: "Real Estate" },
  { text: "Summarize the investment opportunities highlighted using bullet points.", category: "Real Estate" },
  { text: "What are the key factors affecting property values? List them numerically.", category: "Real Estate" },
  { text: "List the pros and cons of the real estate strategies presented in a table.", category: "Real Estate" },
  { text: "Explain the mortgage options covered in the video, including examples in code blocks.", category: "Real Estate" },
  
  // Medical
  { text: "Summarize the new medical research findings using headings and bullet points.", category: "Medical" },
  { text: "Explain the treatment options discussed, comparing them in a Markdown table.", category: "Medical" },
  { text: "List the symptoms and diagnoses mentioned, emphasizing key terms in bold.", category: "Medical" },
  { text: "Provide an overview of the medical procedure demonstrated using numbered steps.", category: "Medical" },
  { text: "Translate the medical jargon into layman's terms, adding footnotes for technical terms.", category: "Medical" },
  
  // Technology
  { text: "Summarize the latest tech innovations discussed using bullet points.", category: "Technology" },
  { text: "Explain how the new software feature works, including code snippets in code blocks.", category: "Technology" },
  { text: "List the steps shown in the tutorial as numbered instructions.", category: "Technology" },
  { text: "Provide troubleshooting tips mentioned in the video, formatted as bullet points.", category: "Technology" },
  { text: "Compare the technologies evaluated using a Markdown table.", category: "Technology" },
  
  // Finance
  { text: "Summarize the investment strategies covered, using bullet points.", category: "Finance" },
  { text: "Explain the economic indicators mentioned, adding footnotes for definitions.", category: "Finance" },
  { text: "List the financial risks discussed in a bullet-point list.", category: "Finance" },
  { text: "Provide an analysis of market trends from the video, including any data in tables.", category: "Finance" },
  { text: "Describe the budgeting tips presented using headings and subheadings.", category: "Finance" },
  
  // Marketing
  { text: "Summarize the marketing campaign strategies using bullet points.", category: "Marketing" },
  { text: "List the target demographics discussed, formatted in a table.", category: "Marketing" },
  { text: "Explain the branding techniques mentioned, using examples in blockquotes.", category: "Marketing" },
  { text: "Provide insights on consumer behavior, highlighting key quotes.", category: "Marketing" },
  { text: "Outline the social media strategies presented using numbered steps.", category: "Marketing" },
  
  // Engineering
  { text: "Summarize the engineering concepts explained, including formulas in code blocks.", category: "Engineering" },
  { text: "List the materials and methods used in the project in a Markdown table.", category: "Engineering" },
  { text: "Explain the design principles discussed using bullet points.", category: "Engineering" },
  { text: "Provide calculations or formulas mentioned, formatted within code blocks.", category: "Engineering" },
  { text: "Describe the problem-solving approaches presented using numbered steps.", category: "Engineering" },
  
  // Art & Design
  { text: "Summarize the artistic techniques demonstrated using bullet points.", category: "Art & Design" },
  { text: "Explain the design principles used, including key quotes in blockquotes.", category: "Art & Design" },
  { text: "List the tools and materials mentioned in a bullet-point list.", category: "Art & Design" },
  { text: "Provide step-by-step instructions from the tutorial using numbered lists.", category: "Art & Design" },
  { text: "Describe the creative process outlined with headings and subheadings.", category: "Art & Design" },
  
  // Music
  { text: "Summarize the music theory concepts explained, including notation in code blocks.", category: "Music" },
  { text: "List the chords and progressions used, formatted as a bullet-point list.", category: "Music" },
  { text: "Explain the techniques demonstrated for playing an instrument using numbered steps.", category: "Music" },
  { text: "Provide the lyrics and their meanings, using blockquotes for the lyrics.", category: "Music" },
  { text: "Describe the production techniques discussed using bullet points.", category: "Music" },
  
  // Health & Fitness
  { text: "Summarize the workout routine presented using numbered steps.", category: "Health & Fitness" },
  { text: "List the nutritional advice given in bullet points.", category: "Health & Fitness" },
  { text: "Explain the health benefits mentioned, highlighting key terms in bold.", category: "Health & Fitness" },
  { text: "Provide tips on maintaining a healthy lifestyle using headings and subheadings.", category: "Health & Fitness" },
  { text: "Describe the mindfulness techniques demonstrated with step-by-step instructions.", category: "Health & Fitness" },
  
  // Travel
  { text: "Summarize the travel itinerary shared, including destinations in a table.", category: "Travel" },
  { text: "List the destinations and attractions mentioned in bullet points.", category: "Travel" },
  { text: "Explain the cultural highlights discussed, using blockquotes for notable comments.", category: "Travel" },
  { text: "Provide travel tips and recommendations, formatted as bullet points.", category: "Travel" },
  { text: "Describe the experiences highlighted with descriptive headings.", category: "Travel" },
  
  // Culinary
  { text: "Summarize the recipe demonstrated, including ingredients and steps in a table.", category: "Culinary" },
  { text: "List the ingredients and measurements used, formatted in a table.", category: "Culinary" },
  { text: "Explain the cooking techniques shown using numbered steps.", category: "Culinary" },
  { text: "Provide step-by-step cooking instructions using a numbered list.", category: "Culinary" },
  { text: "Describe the flavors and presentation tips discussed in bullet points.", category: "Culinary" },
  
  // Sports
  { text: "Summarize the game strategies analyzed using bullet points.", category: "Sports" },
  { text: "List the key players and their performance in a Markdown table.", category: "Sports" },
  { text: "Explain the training techniques discussed using numbered steps.", category: "Sports" },
  { text: "Provide highlights from the sports event in bullet points.", category: "Sports" },
  { text: "Describe the rules and regulations mentioned with headings and subheadings.", category: "Sports" },
  
  // Journalism
  { text: "Summarize the news report presented, including key quotes in blockquotes.", category: "Journalism" },
  { text: "List the facts and figures mentioned using bullet points.", category: "Journalism" },
  { text: "Explain the background context provided with subheadings.", category: "Journalism" },
  { text: "Provide quotes from key individuals in the video using blockquotes.", category: "Journalism" },
  { text: "Describe the potential impact of the events discussed using bullet points.", category: "Journalism" },
  
  // Entertainment
  { text: "Summarize the plot of the film or show discussed using bullet points.", category: "Entertainment" },
  { text: "List the main characters and their roles in a table.", category: "Entertainment" },
  { text: "Explain the themes and motifs presented, including excerpts in blockquotes.", category: "Entertainment" },
  { text: "Provide a critique or review based on the video, highlighting pros and cons.", category: "Entertainment" },
  { text: "Describe the production techniques used in bullet points.", category: "Entertainment" },
  
  // Customer Service
  { text: "Summarize the customer service techniques demonstrated using bullet points.", category: "Customer Service" },
  { text: "List the common customer issues mentioned in a bullet-point list.", category: "Customer Service" },
  { text: "Explain how to handle difficult customers as discussed, using numbered steps.", category: "Customer Service" },
  { text: "Provide an overview of the support tools introduced with subheadings.", category: "Customer Service" },
  { text: "Describe the communication strategies outlined in bullet points.", category: "Customer Service" },
  
  // Science
  { text: "Summarize the scientific research findings presented, including equations in code blocks.", category: "Science" },
  { text: "List the experiments and results shown in a table.", category: "Science" },
  { text: "Explain the theories or models discussed using headings and subheadings.", category: "Science" },
  { text: "Provide definitions for technical terms mentioned, adding footnotes.", category: "Science" },
  { text: "Describe the methodology used in the study using numbered steps.", category: "Science" },
  
  // Fashion
  { text: "Summarize the fashion trends presented using bullet points.", category: "Fashion" },
  { text: "List the style tips mentioned in a bullet-point list.", category: "Fashion" },
  { text: "Explain how to coordinate outfits as discussed using numbered steps.", category: "Fashion" },
  { text: "Provide a guide to the accessories shown in bullet points.", category: "Fashion" },
  { text: "Describe the designer's inspiration using blockquotes.", category: "Fashion" },
  
  // Agriculture
  { text: "Summarize the farming techniques demonstrated using bullet points.", category: "Agriculture" },
  { text: "List the crops and their growing conditions discussed in a table.", category: "Agriculture" },
  { text: "Explain the sustainable practices mentioned with headings and subheadings.", category: "Agriculture" },
  { text: "Provide an overview of the agricultural technologies introduced using bullet points.", category: "Agriculture" },
  { text: "Describe the pest management strategies covered in bullet points.", category: "Agriculture" },
  
  // Podcast
  { text: "Summarize the podcast episode using bullet points.", category: "Podcast" },
  { text: "List the main topics discussed in the podcast with timestamps.", category: "Podcast" },
  { text: "Explain the key takeaways from the podcast using headings and subheadings.", category: "Podcast" },
  { text: "Provide memorable quotes from the podcast host or guests using blockquotes.", category: "Podcast" },
  { text: "Create a table of guests and their main discussion points.", category: "Podcast" },
  
  // Hospitality
  { text: "Summarize the customer service practices in hospitality using bullet points.", category: "Hospitality" },
  { text: "List the operational procedures discussed using numbered steps.", category: "Hospitality" },
  { text: "Explain the guest experience enhancement strategies with subheadings.", category: "Hospitality" },
  { text: "Provide an overview of the event planning tips in bullet points.", category: "Hospitality" },
  { text: "Describe the trends in the hospitality industry mentioned using bullet points.", category: "Hospitality" },
  
  // Logistics
  { text: "Summarize the supply chain processes explained using bullet points.", category: "Logistics" },
  { text: "List the logistics challenges and solutions discussed in a table.", category: "Logistics" },
  { text: "Explain the inventory management techniques presented with headings.", category: "Logistics" },
  { text: "Provide insights on transportation strategies using bullet points.", category: "Logistics" },
  { text: "Describe the warehousing best practices mentioned in bullet points.", category: "Logistics" },
  
  // YouTuber
  { text: "Summarize the YouTube video content using bullet points.", category: "YouTuber" },
  { text: "List the steps or tips provided in the video using numbered lists.", category: "YouTuber" },
  { text: "Highlight important quotes from the YouTuber using blockquotes.", category: "YouTuber" },
  { text: "Provide an outline of the video with headings and subheadings.", category: "YouTuber" },
  { text: "Create a table comparing products or ideas discussed in the video.", category: "YouTuber" },
  
  // Government
  { text: "Summarize the policy proposals outlined using bullet points.", category: "Government" },
  { text: "List the public programs discussed in a bullet-point list.", category: "Government" },
  { text: "Explain the administrative procedures mentioned using numbered steps.", category: "Government" },
  { text: "Provide an overview of the governmental structure described with headings.", category: "Government" },
  { text: "Describe the civic initiatives presented using bullet points.", category: "Government" },
  
];

export default examplePrompts;
