import React, { useState, useEffect, useRef } from 'react';
import './exampleVideoCarousel.css';

import speechThumb from '../../assets/images/edu-thumbnail.jpg';
import eduThumb from '../../assets/images/schoolThumb.jpg';
import bizThumb from '../../assets/images/business-thumbnail.jpg';
import podcastThumb from '../../assets/images/podcaster-thumbnail.jpg';
import youtuberThumb from '../../assets/images/youtuber-thumbnail.jpg';
import legalThumb from '../../assets/images/legal-thumbnail.jpg';
import anyThumb from '../../assets/images/anything-thumbnail.jpg';


import eduExample from '../../assets/images/workspace-edu-examp.jpg';
import podExample from '../../assets/images/podcast-examp-workspace.jpg';
import anyExample from '../../assets/images/bumps-examp-workspace.jpg';
import legalExample from '../../assets/images/legal-examp-workspace.jpg';
import bizExample from '../../assets/images/biz-examp-workspace.jpg';
import youtuberExample from '../../assets/images/youtuber-examp-workspace.jpg';
import presentationExample from '../../assets/images/presentation-examp-workspace.jpg';


const carouselItems = [
  { title: 'Podcast', image: podcastThumb, overlay: podExample },
  { title: 'Any Topic', image: anyThumb, overlay: anyExample },
  { title: 'Education', image: eduThumb, overlay: eduExample },
  { title: 'YouTuber', image: youtuberThumb, overlay: youtuberExample },
  { title: 'Presentation', image: speechThumb, overlay: presentationExample },
  { title: 'Legal', image: legalThumb, overlay: legalExample },
  { title: 'Business', image: bizThumb, overlay: bizExample },
];

const ExampleVideoCarousel = () => {
  const [itemsToShow, setItemsToShow] = useState(3);
  const totalItems = carouselItems.length;
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsRef = useRef(null);
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(false);

  const handleNext = () => {
    if (currentIndex < totalItems - itemsToShow) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  useEffect(() => {
    if (itemsRef.current) {
      itemsRef.current.style.transform = `translateX(-${currentIndex * (100 / itemsToShow)}%)`;
    }
    setIsPrevDisabled(currentIndex === 0);
    setIsNextDisabled(currentIndex >= totalItems - itemsToShow);
  }, [currentIndex, itemsToShow, totalItems]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) setItemsToShow(1);
      else if (window.innerWidth < 992) setItemsToShow(2);
      else setItemsToShow(3);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="ex-carousel-container">
      <div className="ex-carousel-title">
        WHAT VIDEO WILL YOU <span className="ex-highlight">CHAT</span> WITH TODAY?
      </div>
      <div className="ex-carousel">
        <button
          className="ex-arrow ex-left-arrow"
          onClick={handlePrev}
          disabled={isPrevDisabled}
          aria-label="Previous"
        >
          <svg width="24" height="24" viewBox="0 0 24 24">
            <path fill="#ffffff" d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
          </svg>
        </button>
        <div className="ex-carousel-inner">
        <div className="ex-carousel-items" ref={itemsRef}>
            {carouselItems.map((item, index) => (
              <div
                className="ex-carousel-item"
                key={index}
                style={{ flex: `0 0 calc(100% / ${itemsToShow})` }}
              >
                {/* Title at the Top */}
                <p className="ex-item-title">{item.title}</p>

                {/* Main Image */}
                <img src={item.image} alt={item.title} className="ex-item-image" />

                {/* Overlay Image Below the Main Image */}
                {item.overlay && (
                  <div className="ex-overlay-image-container">
                    <img src={item.overlay} alt={`${item.title} overlay`} className="ex-overlay-image" />
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="ex-carousel-gradient ex-carousel-gradient-left"></div>
          <div className="ex-carousel-gradient ex-carousel-gradient-right"></div>
        </div>
        <button
          className="ex-arrow ex-right-arrow"
          onClick={handleNext}
          disabled={isNextDisabled}
          aria-label="Next"
        >
          <svg width="24" height="24" viewBox="0 0 24 24">
            <path fill="#ffffff" d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default ExampleVideoCarousel;
