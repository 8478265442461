// FIREBASE
import { ref, uploadBytesResumable, getDownloadURL, updateMetadata } from 'firebase/storage';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { storage, videoStorage, db } from '../firebase';

// FUNCTIONS
import { getFunctions, httpsCallable } from 'firebase/functions';
import { v4 as uuidv4 } from 'uuid';
import base64 from 'base-64';

const DEFAULT_THUMBNAIL_URL = process.env.REACT_APP_DEFAULT_THUMBNAIL_URL;

const validateFile = (file) => {
    return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = () => {
            window.URL.revokeObjectURL(video.src);
            if (video.duration > 0 && video.videoWidth > 0 && video.videoHeight > 0) {
                let videoQuality;
                if (video.videoWidth >= 3840) {
                    videoQuality = '4K';
                } else if (video.videoWidth >= 1920) {
                    videoQuality = 'HD';
                } else if (video.videoWidth >= 1280) {
                    videoQuality = '720';
                } else {
                    videoQuality = 'SD';
                }
                resolve({ videoQuality, videoDuration: video.duration });
            } else {
                reject('Invalid video file');
            }
        };

        video.onerror = () => {
            window.URL.revokeObjectURL(video.src);
            reject('Invalid video file');
        };

        video.src = URL.createObjectURL(file);
    });
};

const generate16DigitVideoId = () => {
    const uniqueId = uuidv4();
    const base64Id = base64.encode(uniqueId)
        .replace(/\+/g, '-') // Replace + with -
        .replace(/\//g, '_') // Replace / with _
        .replace(/=+$/, ''); // Remove ending =
    return base64Id.slice(0, 16);
};

const generateVideoId = async () => {
    let videoId = generate16DigitVideoId();
    let videoDocRef = doc(db, "globalBumps", videoId);
    let videoDoc = await getDoc(videoDocRef);

    while (videoDoc.exists()) {
        videoId = generate16DigitVideoId();
        videoDocRef = doc(db, "globalBumps", videoId);
        videoDoc = await getDoc(videoDocRef);
    }

    await setDoc(videoDocRef, { exists: true });
    return videoId;
};

const functions = getFunctions();
const getCaptionsBump2GB = httpsCallable(functions, 'getCaptionsBump2GB', { timeout: 600000 });
const getCaptionsBump4GB = httpsCallable(functions, 'getCaptionsBump4GB', { timeout: 600000 });
const getCaptionsBump8GB = httpsCallable(functions, 'getCaptionsBump8GB', { timeout: 600000 });
const getCaptionsBump16GB = httpsCallable(functions, 'getCaptionsBump16GB', { timeout: 900000 });
const getCaptionsBump32GB = httpsCallable(functions, 'getCaptionsBump32GB', { timeout: 1200000 });
const uploadBump = async (file, user, setUploadProgress, setVideoId, setShowPopup, setThumbnailUrl, storagePathRef, uploadTaskRef, thumbnailUrl) => {
    if (!file) return;

    if (file.size > 31 * 1024 * 1024 * 1024) {
        console.error("File size exceeds the 31GB limit:", file.size);
        return;
    }

    let videoMetadata;
    try {
        videoMetadata = await validateFile(file);
    } catch (validationError) {
        console.error("File validation error:", validationError);
        return;
    }

    const generatedVideoId = await generateVideoId();
    setVideoId(generatedVideoId);
    setShowPopup(true);

    const metadata = {
        contentType: file.type,
        customMetadata: {
            uploadedBy: user.uid,
            description: 'Video upload',
            videoFileName: file.name,
            videoThumbnail: thumbnailUrl || DEFAULT_THUMBNAIL_URL,
            videoQuality: videoMetadata.videoQuality,
            videoDuration: videoMetadata.videoDuration.toString()
        }
    };

    // console.log("Initial thumbnail URL:", thumbnailUrl || DEFAULT_THUMBNAIL_URL);

    const storageRef = ref(videoStorage, `${user.uid}/${generatedVideoId}`);
    storagePathRef.current = storageRef;
    const uploadTask = uploadBytesResumable(storageRef, file, metadata);
    uploadTaskRef.current = uploadTask;

    uploadTask.on('state_changed',
        (snapshot) => {
            const videoProgress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setUploadProgress(videoProgress);
        },
        (error) => {
            // console.error("Upload error:", error);
        },
        async () => {
            try {
                await getDownloadURL(uploadTask.snapshot.ref);

                const thumbnailstorageRef = ref(storage, `bumpThumbnails/${user.uid}/${generatedVideoId}`);
                let thumbnailDownloadURL = thumbnailUrl;

                try {
                    thumbnailDownloadURL = await getDownloadURL(thumbnailstorageRef);
                    // console.log("Fetched thumbnail URL from storage:", thumbnailDownloadURL);

                    const newMetadata = {
                        customMetadata: {
                            videoThumbnail: thumbnailDownloadURL
                        }
                    };
                    await updateMetadata(storageRef, newMetadata);
                    setThumbnailUrl(thumbnailDownloadURL);
                    // console.log("Updated metadata with new thumbnail URL:", thumbnailDownloadURL);
                } catch (error) {
                    // console.warn('Thumbnail URL fetch failed, using default:', error);
                }

                setUploadProgress(100);

                const payload = {
                    videoMetadata: {
                        uploadedBy: user.uid,
                        videoId: generatedVideoId,
                        description: 'Video upload',
                        videoFileName: file.name,
                        videoThumbnail: thumbnailDownloadURL || DEFAULT_THUMBNAIL_URL,
                        videoQuality: videoMetadata.videoQuality,
                        videoDuration: videoMetadata.videoDuration.toString()
                    }
                };

                // Determine which captions bump function to use based on file size
                let captionsFunction;
                if (file.size <= 1 * 1024 * 1024 * 1024) { // <= 1GB
                    captionsFunction = getCaptionsBump2GB;
                } else if (file.size <= 3 * 1024 * 1024 * 1024) { // <= 3GB
                    captionsFunction = getCaptionsBump4GB;
                } else if (file.size <= 7 * 1024 * 1024 * 1024) { // <= 7GB
                    captionsFunction = getCaptionsBump8GB;
                } else if (file.size <= 15 * 1024 * 1024 * 1024) { // <= 15GB
                    captionsFunction = getCaptionsBump16GB;
                } else if (file.size <= 31 * 1024 * 1024 * 1024) { // <= 31GB
                    captionsFunction = getCaptionsBump32GB;
                } else {
                    console.error('File size exceeds the 32GB limit:', file.size);
                    return;
                }

                // Call the appropriate captions bump function
                await captionsFunction(payload);

                // Clear local storage
                const localStorageKey = `videoCache_${user.uid}_BumpedVideos`;
                localStorage.removeItem(localStorageKey);
                localStorage.removeItem(`recentBump_${user.uid}`);

            } catch (error) {
                console.error("Error during upload completion or thumbnail URL handling:", error);
            }
        }
    );
};

export { uploadBump, validateFile, generate16DigitVideoId, generateVideoId };

