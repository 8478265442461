// src/components/TemplateWorkspace/ShortcutsWorkspace.js

import React, { useState, useEffect, useRef } from 'react';
import './shortcutsWorkspace.css';

// Import all necessary images
import descriptionShortcutIcon from '../../assets/images/linelength-v1.png';
import timestampShortcutIcon from '../../assets/images/clock-v2.png';
import takeawaysShortcutIcon from '../../assets/images/takeaway-white-v1.png'; 
import titlesShortcutIcon from '../../assets/images/title-v1.png'; 
import tagsShortcutIcon from '../../assets/images/tag-white-v1.png'; 
import bumpPlanLogo from '../../assets/images/bumpups-white.png';

import toolBox from '../../assets/images/toolbox-v1.png';
import hammerIcon from '../../assets/images/hammer-v1.png';


// Import the generateShortcuts function
import { generateShortcuts } from './generateShortcuts';

const ShortcutsWorkspace = ({
    isScTimestampsEnabled,
    setIsScTimestampsEnabled,
    isScDescriptionEnabled,
    setIsScDescriptionEnabled,
    isScTakeawaysEnabled,
    setIsScTakeawaysEnabled,
    isScTitlesEnabled,
    setIsScTitlesEnabled,
    isScTagsEnabled,
    setIsScTagsEnabled,
    isTranscriptAvailable,
    isLocalTranscriptAvailable,
    content_id, 
    timestamps,
    summary,
    takeaways,
    titles,
    hashtags,
    user,
}) => {
    // Helper function to check if a value has meaningful data
    const hasData = (value) => {
        if (Array.isArray(value)) {
            return value.length > 0;
        }
        if (typeof value === 'string') {
            return value.trim().length > 0;
        }
        if (value && typeof value === 'object') {
            return Object.keys(value).length > 0;
        }
        return false;
    };

    // Define the shortcuts data with individual images
    const shortcuts = [
        { key: 'timestamps', label: 'Video Timestamps', alt: 'Timestamps Icon', image: timestampShortcutIcon, checked: isScTimestampsEnabled, setter: setIsScTimestampsEnabled },
        { key: 'description', label: 'Video Description', alt: 'Description Icon', image: descriptionShortcutIcon, checked: isScDescriptionEnabled, setter: setIsScDescriptionEnabled },
        { key: 'takeaways', label: 'Key Takeaways', alt: 'Takeaways Icon', image: takeawaysShortcutIcon, checked: isScTakeawaysEnabled, setter: setIsScTakeawaysEnabled },
        { key: 'titles', label: 'Video Titles', alt: 'Titles Icon', image: titlesShortcutIcon, checked: isScTitlesEnabled, setter: setIsScTitlesEnabled },
        { key: 'tags', label: 'Video Tags', alt: 'Tags Icon', image: tagsShortcutIcon, checked: isScTagsEnabled, setter: setIsScTagsEnabled },
    ];

    // Handler to toggle switches
    const handleToggle = (setter) => {
        setter(prev => !prev);
    };

    // State to manage button disabled state
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const cooldownRef = useRef(null);

    const COOLDOWN_DURATION = 300; // seconds

    // Function to start cooldown
    const startCooldown = () => {
        
        if (allTogglesFalse) return;

        setIsButtonDisabled(true);
        const cooldownEnd = Date.now() + COOLDOWN_DURATION * 1000;
        localStorage.setItem(`${content_id}_shortcut_debounce`, cooldownEnd);

        cooldownRef.current = setInterval(() => {
            const now = Date.now();
            const remaining = Math.round((cooldownEnd - now) / 1000);
            if (remaining <= 0) {
                clearInterval(cooldownRef.current);
                setIsButtonDisabled(false);
                localStorage.removeItem(`${content_id}_shortcut_debounce`);
            } else {
                // Optional: Update remaining time display if needed
            }
        }, 1000);
    };

    // Handler for Generate Shortcuts button
    const handleGenerateShortcuts = async () => {
        if (isButtonDisabled || allTogglesFalse) return; // Add check for allTogglesFalse


        // console.log('CLICKED SHORTCUT');


        // Disable the button and start cooldown
        startCooldown();

        // Construct the options object based on current prop values
        const shortcutOptions = {
            timestamps: isScTimestampsEnabled,
            description: isScDescriptionEnabled,
            takeaways: isScTakeawaysEnabled,
            titles: isScTitlesEnabled,
            tags: isScTagsEnabled,
        };

        try {
            // Call the generateShortcuts function with contentId and options
            const data = await generateShortcuts(content_id, shortcutOptions, user);
            
            // Handle the response as needed
            // console.log('Shortcuts generated successfully:', data);
            // You can add further logic here, such as updating the UI or notifying the user
        } catch (error) {
            // Handle any errors that occurred during the function call
            // console.error('Failed to generate shortcuts:', error);
            // Optionally, display an error message to the user
            // If there's an error, you might want to reset the cooldown
            // Uncomment the following lines if desired:
            // clearInterval(cooldownRef.current);
            // setIsButtonDisabled(false);
            // setCooldownTime(0);
            // localStorage.removeItem(`${content_id}_shortcut_debounce`);
        }
    };

    // Compute if all toggles are false
    const allTogglesFalse = !isScTimestampsEnabled && 
                            !isScDescriptionEnabled && 
                            !isScTakeawaysEnabled && 
                            !isScTitlesEnabled && 
                            !isScTagsEnabled;

    // Determine if any data exists using the hasData helper function
    const isAnyDataExist = 
        hasData(timestamps) || 
        hasData(summary) || 
        hasData(takeaways) || 
        hasData(titles) || 
        hasData(hashtags);

    // Effect to check local storage on mount
    useEffect(() => {
        const storedCooldownEnd = localStorage.getItem(`${content_id}_shortcut_debounce`);
        if (storedCooldownEnd) {
            const remaining = Math.round((storedCooldownEnd - Date.now()) / 1000);
            if (remaining > 0) {
                setIsButtonDisabled(true);

                cooldownRef.current = setInterval(() => {
                    const now = Date.now();
                    const rem = Math.round((storedCooldownEnd - now) / 1000);
                    if (rem <= 0) {
                        clearInterval(cooldownRef.current);
                        setIsButtonDisabled(false);
                        localStorage.removeItem(`${content_id}_shortcut_debounce`);
                    } else {
                        // Optional: Update remaining time display if needed
                    }
                }, 1000);
            } else {
                // Cooldown expired
                localStorage.removeItem(`${content_id}_shortcut_debounce`);
            }
        }

        // Cleanup on unmount
        return () => {
            if (cooldownRef.current) {
                clearInterval(cooldownRef.current);
            }
        };
    }, [content_id]);

    return (
        <div className="shortcuts-main-container">
            {/* Shortcuts Workspace Section */}
            <div className="shortcuts-workspace-container">
                {/* Title and Badge Container */}
                <div className="shortcuts-title-container">
                    <div className="shortcuts-title">Bump Shortcuts</div>
                    <span className="shortcuts-badge">Toolbox</span>
                </div>
                
                <div className="shortcuts-description">
                    <div>
                        Shortcuts offer prebuilt outputs like video timestamps, allowing you to enable features directly without adding them to your prompts.
                    </div>
                </div>

                <div className="shortcuts-switches-container">
                    {/* Render each switch */}
                    {shortcuts.map(item => {
                        // Compute the disabled state for each checkbox
                        const isDisabled = ((isAnyDataExist && isTranscriptAvailable) || (isAnyDataExist && isLocalTranscriptAvailable)) || isButtonDisabled;

                        return (
                            <div 
                                className={`shortcuts-switch-item ${isAnyDataExist ? 'disabled' : ''}`} 
                                key={item.key}
                            >
                                <img src={item.image} alt={item.alt} className="shortcuts-icon" />
                                <label className="shortcuts-label" htmlFor={`${item.key}-toggle`}>{item.label}</label>
                                <input
                                    type="checkbox"
                                    checked={item.checked}
                                    onChange={() => handleToggle(item.setter)}
                                    className="shortcuts-toggle"
                                    id={`${item.key}-toggle`}
                                    disabled={isDisabled} // Updated disabled prop
                                />
                                <label htmlFor={`${item.key}-toggle`} className="shortcuts-toggle-label"></label>
                            </div>
                        );
                    })}
                </div>
                {/* Conditionally render the button only if transcript is available and no data exists */}
                {(isTranscriptAvailable || isLocalTranscriptAvailable) && !isAnyDataExist && (
                        <button
                        className="shortcuts-submit-button"
                        onClick={handleGenerateShortcuts}
                        disabled={isButtonDisabled || allTogglesFalse}
                    >
                        <img
                            src={isButtonDisabled ? hammerIcon : toolBox}
                            alt="Shortcut Icon"
                            className="shortcuts-icon"
                        />
                        <span className="button-text">
                            {isButtonDisabled ? 'Generating...' : 'Generate Shortcuts'}
                        </span>
                    </button>
                )}

            </div>

            <div className="shortcuts-processing-container">
            <div className="shortcuts-processing-card">
                    <div className="shortcuts-processing-content">
                        <div className="shortcuts-title-section">How do I chat with Bump AI?</div>
                        <div className="shortcuts-description-section">
                            Ask questions, request summaries, analyses, and more.
                        </div>
                        <a 
                            href="https://intercom.help/bumpups/en/articles/10035173-how-do-i-chat-with-bump-ai" 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            className="shortcuts-link-no-underline"
                        >
                            <button className="shortcuts-processing-start-btn">LEARN MORE</button>
                        </a>
                    </div>
                    <div className="shortcuts-processing-icon">
                        <img className="shortcuts-upload-language" src={bumpPlanLogo} alt="Bump Icon" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShortcutsWorkspace;
