import { getFunctions, httpsCallable } from 'firebase/functions';

// Initialize Firebase Functions
const functions = getFunctions();

// Create a callable function reference
const generateShortcutsCallable = httpsCallable(functions, 'generateWorkspaceShortcuts');

const generateShortcuts = async (content_id, options = {}) => {
  // Default isReprocess to false if not provided
  const isReprocess = options.isReprocess ?? false;

  // Construct the payload
  const payload = {
    contentId: `workspace-${content_id}`,
    isSCTimestampsEnabled: options.timestamps ?? false,
    isSCDescriptionEnabled: options.description ?? false,
    isSCTakeawaysEnabled: options.takeaways ?? false,
    isSCTitlesEnabled: options.titles ?? false,
    isSCTagsEnabled: options.tags ?? false,
    isReprocess, // Add isReprocess to payload, defaults to false
  };

  try {
    // Call the Cloud Function with the payload
    const result = await generateShortcutsCallable(payload);

    
    // Handle the response as needed
    // console.log('Shortcuts generated successfully:', result.data);

    return result.data;
  } catch (error) {
    // Handle errors appropriately
    // console.error('Error generating shortcuts:', error);
    throw error;
  }
};

export { generateShortcuts };
