import React, { useState } from 'react';
import './WorkspaceHeader.css';

import draftIcon from '../../../assets/images/workspace-draft-v1.png';
import activeIcon from '../../../assets/images/bumparrow.png';
import uploadingIcon from '../../../assets/images/upload-white-v1.png'; // Assuming an uploading icon exists
import errorIcon from '../../../assets/images/v1-warning.png'; // Assuming an uploading icon exists


const WorkspaceHeader = ({ 
  workspaceTitle, 
  setWorkspaceTitle, 
  isTranscriptAvailable, 
  isLocalTranscriptAvailable,
  workspaceCreatedFunction,
  isSaveButtonEnabled, 
  isYTProcessing,
  isLOCALProcessing,
  isTranscriptError,
}) => {
  const [buttonText, setButtonText] = useState('Save Workspace');
  const [isFocused, setIsFocused] = useState(false);

  // Handler for saving the workspace
  const handleSave = async () => {
    if (workspaceTitle.trim() === '') return;

    try {
      setButtonText('Saving...');
      if (workspaceCreatedFunction) await workspaceCreatedFunction();

      setButtonText('Saved 🎉');
      setTimeout(() => {
        setButtonText('Save Workspace');
      }, 3000);
    } catch (err) {
      setButtonText('Save Workspace');
    }
  };

  const isMaxLength = workspaceTitle.length === 32;
  const isTitleEmpty = workspaceTitle.trim() === '';


  return (
    <div className="workspace-header-container">
      <div className="title-save-container">
        <div className="title-input-wrapper">
          <input
            type="text"
            className={`workspace-title-input ${isMaxLength ? 'input-max' : ''} ${
              workspaceTitle !== '' ? 'has-title' : ''
            } ${isFocused ? 'input-focused' : ''} ${isTitleEmpty ? 'input-error' : ''}`}
            placeholder="Enter workspace title"
            value={workspaceTitle}
            onChange={(e) => setWorkspaceTitle(e.target.value)}
            aria-label="Workspace Title"
            maxLength={32}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />

          {/* Active Badge */}
          {(isTranscriptAvailable || isLocalTranscriptAvailable) && (
            <div className="badge badge-active">
              <img src={activeIcon} alt="Active Icon" className="active-icon-workspace" />
              <span className="active-text">Active</span>
            </div>
          )}

          {/* Need Title Badge */}
          {(!isTranscriptAvailable && !isLocalTranscriptAvailable) && isTitleEmpty && (
            <div className="badge badge-need-title">
              <img src={draftIcon} alt="Need Title Icon" className="need-title-icon" />
              <span className="need-title-text">Title</span>
            </div>
          )}

          {/* Draft Badge */}
          {(!isTranscriptAvailable && !isLocalTranscriptAvailable) && !isTitleEmpty && !isYTProcessing && !isLOCALProcessing && !isTranscriptError &&(
            <div className="badge badge-draft">
              <img src={draftIcon} alt="Draft Icon" className="draft-icon" />
              <span className="draft-text">Draft</span>
            </div>
          )}

          {/* Uploading Badge */}
          {!isTranscriptAvailable && isYTProcessing && (
            <div className="badge badge-uploading">
              <img src={uploadingIcon} alt="Uploading Icon" className="draft-icon" />
              <span className="draft-text">Uploading</span>
            </div>
          )}

          {/* Uploading Badge for LOCAL Processing */}
          {!isLocalTranscriptAvailable && isLOCALProcessing && (
            <div className="badge badge-uploading">
              <img src={uploadingIcon} alt="Processing Icon" className="draft-icon" />
              <span className="draft-text">Processing</span>
            </div>
          )}

          {/* Error Badge */}
          {(!isTranscriptAvailable && !isLocalTranscriptAvailable && isTranscriptError) && (
            <div className="badge badge-error">
             <img src={errorIcon} alt="Need Title Icon" className="need-title-icon" />
             <span className="need-title-text">Failed</span>
            </div>
          )}



        </div>

        <button
          className="save-workspace-button"
          onClick={handleSave}
          disabled={!isSaveButtonEnabled || isTitleEmpty}
          aria-disabled={!isSaveButtonEnabled || isTitleEmpty}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default WorkspaceHeader;
